import { ProfileType } from "../models/profile.model";

export const MODULE_ROUTE_PREFIX = '/module/:moduleId';

type Module = {
    id: string,
    image: any,
    title: string,
    profile: ProfileType|null,
    render: () => JSX.Element
}

export default Module;