import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import _ from 'underscore';
import { UserContext } from '../../App';
import usePersistentState from '../../hooks/usePersistentState';
import { ProfileType } from '../../models/profile.model';
import Button from '../../uiKit/Button/Button';
import Tooltip from '../../uiKit/tooltip/Tooltip';
import { MODULE_ROUTE_PREFIX } from '../module';
import { Project, ProjectType, Task } from './models';

import './todo.scss';

export default () => {
    const history = useHistory();
    const [creating, setCreating] = React.useState(false);
    const [projects, setProjects] = React.useState<Project[]>([]);
    const user = React.useContext(UserContext);
    const [profile] = usePersistentState<ProfileType|null>('profile', null);
    const [groupExpand, setGroupExpand] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState(true);
    const [showType, setShowType] = React.useState('all');

    React.useEffect(() => {
        (async() => {
            if (!user) return;
            const projectSnapshot = (await firebase
                .firestore()
                .collection('todo')
                .where('owner', '==', user.uid)
                .where('profile', '==', profile)
                .get());

            if (projectSnapshot.empty) {
                setProjects([]);
                setIsLoading(false);
                return;
            }

            let projectsFetched: Project[] = [];

            for(const project of projectSnapshot.docs) {
                if (!project.data().removed) {
                    projectsFetched[project.id as any] = {
                        id: project.id,
                        ...project.data() as Project,
                        tasks: []
                    };
                }
            }

            // groups
            for(const key in projectsFetched) {
                if (projectsFetched[key].parent) {
                    if (!projectsFetched[projectsFetched[key].parent as any]) {
                        projectsFetched[key].parent = undefined;
                    } else {
                        if (!projectsFetched[projectsFetched[key].parent as any].childrens) {
                            projectsFetched[projectsFetched[key].parent as any].childrens = [];
                        }

                        projectsFetched[projectsFetched[key].parent as any].childrens?.push(projectsFetched[key]);
                    }
                }
            }

            setProjects(_.sortBy(Object.values(projectsFetched).filter((project) => !project.parent), 'type').reverse());

            setIsLoading(false);
        })();
    }, [user]);

    /*const createProject = (isGroup = false) => {
        setCreating(true);
        setTimeout(async() => {
            const element = document.getElementById('newProject');
            element?.addEventListener('keydown', async(event) => {
                if (!user) return;
                if (event.key === 'Enter') {
                    const projectNew: Project = {
                        title: element.innerText,
                        owner: user.uid,
                        profile: profile as string,
                        tasks: [],
                        isGroup
                    }

                    setIsLoading(true);
                    await firebase.firestore().collection('todo').add(projectNew);
                    window.location.reload();
                    event.preventDefault();
                    return;
                }

                if (event.key === 'Escape') {
                    setCreating(false);
                }
            });

            element?.addEventListener('focusout', () => {
                setCreating(false);
            });

            element?.focus();
        }, 100);
    };*/

    const groupDrop = async(event: any) => {
        event.preventDefault();
        setIsLoading(true);
        var projectId = event.dataTransfer.getData('projectId');
        var currentIsGroup = event.dataTransfer.getData('isGroup');
        let groupId = event.currentTarget.dataset.projectid;
        let dropedProject = event.currentTarget.dataset.projectid;
        const isGroup = event.currentTarget.dataset.isgroup;

        console.log(currentIsGroup, isGroup);

        if (currentIsGroup == "true" && isGroup == "true") {
            swal('Ops!', 'Você não pode juntar dois grupos.', 'error');
            setIsLoading(false);
            return;
        }

        if (isGroup !== "true") {
            const input = await swal('Digite o nome para o novo grupo', {
                content: 'input' as any
            });
            if (!input) {
                swal('Cancelado!', 'O agrupamento foi cancelado', 'error')
                setIsLoading(false);
                return;
            }
    
            const projectNew: Project = {
                title: input,
                owner: user?.uid || '',
                profile: profile as string,
                tasks: [],
                type: ProjectType.GROUP
            }
    
            const newGroup = await firebase.firestore().collection('todo').add(projectNew);
            groupId = newGroup.id;

            await firebase.firestore().collection('todo').doc(dropedProject).set({
                parent: groupId
            }, {merge: true});
        }

        await firebase.firestore().collection('todo').doc(projectId).set({
            parent: groupId
        }, {merge: true});

        window.location.reload();
    };

    const getFinishTasks = (tasks: Task[]) => {
        return tasks.reduce((total, task) => task.done ? total+1 : total, 0);
    };

    const getPercentage = (tasks: Task[]) => {
        if (tasks.length == 0) return 0;
        const totalFinishTasks = getFinishTasks(tasks);
        return ((100 * totalFinishTasks) / tasks.length).toFixed(0);
    };

    const removeGroup = async(projectId: string) => {
        const confirmBox = await Swal.fire({
            text: 'Você irá remover grupo, e todos os projetos ficaram na raiz novmente.',
            icon: 'question',
            denyButtonText: 'Não remover.',
            showDenyButton: true,
            confirmButtonText: 'Remover grupo'
        });
        
        if (!confirmBox.isConfirmed) return;
        setIsLoading(true);
        await firebase.firestore().collection('todo').doc(projectId).delete();
        window.location.reload();
    };

    const createNew = async() => {
        const data = await Swal.fire({
            title: 'Criar um novo',
            html: `
                <input id="new-title" class="swal2-input" placeholder="Título" />
                <select id="new-type" class="swal2-input">
                    <option value="project">Projeto</option>
                    <option value="group">Grupo</option>
                    <option value="routine">Rotina</option>
                    <option value="checklist">Checklist</option>
                </select>
            `,
            showDenyButton: true,
            denyButtonText: 'Cancelar',
            preConfirm: () => {
                return [
                    (document.getElementById('new-title') as any)?.value,
                    (document.getElementById('new-type') as any)?.value
                ]
            }
        });

        if (!data.isConfirmed || !data.value) return;
        const [title, type] = data.value;

        const projectNew: Project = {
            title,
            owner: user?.uid || '',
            profile: profile as string,
            tasks: [],
            type
        }

        setIsLoading(true);
        const todoRef = firebase.firestore().collection('todo').doc();
        await todoRef.set(projectNew);
        projects.unshift({
            ...projectNew,
            id: todoRef.id
        });
        setIsLoading(false);
    };

    if (isLoading) {
        return (
            <div className="container">
                <Button color="black" className="back" onClick={() => history.replace('/dashboard')}>Voltar ao dashboard</Button>

                <Tooltip content="Carregando..." />
            </div>
        )
    }

    return (
        <div className="container">
            <Button color="black" className="back" onClick={() => history.replace('/dashboard')}>Voltar ao dashboard</Button>
            <div className="toggle-types">
                <Button color="black" className={`toggle-type all ${showType == 'all' ? 'inverted' : null}`} onClick={() => setShowType('all')}>
                    Tudos
                </Button>

                <Button color="black" className={`toggle-type routines ${showType == 'routine' ? 'inverted' : null}`} onClick={() => setShowType('routine')}>
                    Rotinas
                </Button>

                <Button color="black" className={`toggle-type checklist ${showType == 'checklist' ? 'inverted' : null}`} onClick={() => setShowType('checklist')}>
                    Checklist
                </Button>

                <Button color="black" className={`toggle-type list ${showType == 'project' ? 'inverted' : null}`} onClick={() => setShowType('project')}>
                    Lista
                </Button>

                <Button color="black" className={`toggle-type group ${showType == 'group' ? 'inverted' : null}`} onClick={() => setShowType('group')}>
                    Grupo
                </Button>
            </div>
            <div className="project new" onClick={() => createNew()}>
                <div className="header">
                    <div className="title">
                        <h2>Criar novo</h2>
                        <small>Criar um novo projeto, grupo ou rotina.</small>
                    </div>
                </div>
            </div>

            {projects.map((project) => {

                if (project.type != ProjectType.ROUTINE && showType == 'routine') return '';
                if (project.type != ProjectType.CHECKLIST && showType == 'checklist') return '';
                if (project.type != ProjectType.PROJECT && showType == 'project') return '';
                if (project.type != ProjectType.GROUP && showType == 'group') return '';

                return (
                <div 
                    key={project.id}
                    data-isgroup={project.type == ProjectType.GROUP}
                    data-projectid={project.id}
                    draggable={true}
                    onDragOver={(ev) => ev.preventDefault()}
                    onDrop={groupDrop}
                    onDragStart={(event) => {
                        event.dataTransfer.setData('projectId', project.id || '');
                        event.dataTransfer.setData('isGroup', (project.type == ProjectType.GROUP).toString());
                    }}
                    className={`project ${project.type}`} 
                    onClick={() => {
                        if (project.type == ProjectType.GROUP) {
                            if (groupExpand === project.id) {
                                return setGroupExpand('');
                            }

                            return setGroupExpand(project.id || '');
                        }
                        history.push(`${MODULE_ROUTE_PREFIX.replace(':moduleId', 'todo')}/${project.id}`)
                    }}>
                    <div className="header">
                        <div className="title">
                            <h2>{project.title}</h2>
                            <small>{
                                {
                                    [ProjectType.PROJECT]: 'Lista',
                                    [ProjectType.ROUTINE]: 'Rotina',
                                    [ProjectType.GROUP]: 'Grupo',
                                    [ProjectType.CHECKLIST]: 'Checklist'
                                }[project.type]
                            }</small>
                        </div>

                        {project.type == ProjectType.GROUP ? (
                            <h2 className="remove" onClick={(evt) => {
                                removeGroup(project.id || '');
                                evt.preventDefault();
                                evt.stopPropagation();
                            }}><FontAwesomeIcon icon={faTrash} className="remove" /></h2>
                        ) : null}
                        
                    </div>

                    {groupExpand === project.id && project.childrens ? (
                        project.childrens.map((projectChildren) => {
                            return (
                            <div className="project children" onClick={() => history.push(`${MODULE_ROUTE_PREFIX.replace(':moduleId', 'todo')}/${projectChildren.id}`)}>
                                <div className="header">
                                    <div className="title">
                                        <h2>{projectChildren.title}</h2>
                
                                    </div>
                                </div>  
                            </div>
                            )
                        })
                    ) : null}
                </div>
                );
            })}
        </div>
    );
};