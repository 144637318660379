import * as React from 'react';
import './Auth.scss';
import './background.jpg';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../App';

export default () => {
    const signIn = React.useRef<any>();
    const [sendingCode, setSendingCode] = React.useState<boolean>(false);
    const history = useHistory();
    const user = React.useContext(UserContext);

    React.useEffect(() => {
        if (user) {
            history.push('/');
        }
    }, [user, history]);

    const loginWithGoogle = async() => {
        setSendingCode(true);
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        firebase.auth().signInWithPopup(provider).then(() => {
            history.push('/');
        }).catch((err) => {
            console.error(err);
            alert('Ocorreu um erro ao efetuar o login.');
        })
    };

    return (
        <div className="login-container">
            <div className="overlay"></div>
            <div className="content">
                <h2>DantasCenter</h2>
                <button id="sign-in" disabled={sendingCode} ref={signIn} onClick={() => loginWithGoogle()} className="button full">{sendingCode ? 'Carregando...' : 'Entrar'}</button>
            </div>
        </div>
    );
}