import * as React from 'react';
import Swal from 'sweetalert2';
import './tooltip.scss';

export default (props: any) => {
    /*const modal = Swal.mixin({
        toast: true,
        position: 'top-left',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 9999999,
        icon: 'info'
    });

    React.useEffect(() => {
        const content = props.content || props.children;
        if (!content) {
            modal.close();
            return;
        }

        modal.fire({
            title: content
        });
        
    }, [props.children, props.content]);

    React.useLayoutEffect(() => {
        modal.close();
    });*/

    return (
        <div className="tooltip">{props.content || props.children}</div>
    );
};