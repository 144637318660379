import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotesList from './NotesList';
import NoteView from './NoteView';
import { MODULE_ROUTE_PREFIX } from '../module';

export default () => {
    return (
        <Switch>
            <Route path={`${MODULE_ROUTE_PREFIX}/view/:noteId`}>
               <NoteView /> 
            </Route>

            <Route path="/">
                <NotesList />
            </Route>
        </Switch>
    )
};