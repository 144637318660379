import firebase from 'firebase';
import moment from 'moment';

export enum ProjectType {
    PROJECT = 'project',
    GROUP = 'group',
    ROUTINE = 'routine',
    CHECKLIST = 'checklist'
};

export type Task = {
    id?: string,
    content: string,
    done: boolean,
    created_at?: firebase.firestore.Timestamp,
    doned_at?: firebase.firestore.Timestamp,
    expire_in?: firebase.firestore.Timestamp
};

export type Routine = {
    id?: string,
    title: string,
    tasks: Array<Task>,
    created_at: firebase.firestore.Timestamp
};

export enum AutoCreatePeriod {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly'
}

export enum AutoCreateDayOfWeek {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday'
}

export type AutoCreate = {
    period: AutoCreatePeriod,
    hour: number,
    day?: number,
    dayOfWeek?: AutoCreateDayOfWeek
};

export type Project = {
    id?: string,
    title: string,
    owner: string,
    tasks: Array<Task>,
    profile: string,
    type: ProjectType,
    parent?: string,
    childrens?: Array<Project>,
    routines?: Array<Routine>,
    template?: Array<Task>,
    autocreate?: AutoCreate
};