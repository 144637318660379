import firebase from 'firebase';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Button from '../../uiKit/Button/Button';
import ListMenu from '../../uiKit/ListMenu/ListMenu';
import Tooltip from '../../uiKit/tooltip/Tooltip';

export default () => {

    const [isLoading, setIsLoading] = React.useState(true);
    const [lists, setLists] = React.useState<any>([]);
    const [listSelected, setListSelected] = React.useState<null|string>("vh5DUIp2VL052qQdLnxr");
    const [items, setItems] = React.useState<any>([]);
    const history = useHistory();

    const fetchList = async() => {
        const lists = await firebase.firestore().collection('shopping-list').get();

        setLists(lists.docs.map((list) => {
            return {
                id: list.id, 
                ...list.data()
            };
        }) as any);
    };

    const fetchItems = async() => {
        if (!listSelected) return;
        const items = await firebase.firestore().collection('shopping-list').doc(listSelected).collection('items').get();

        setItems(items.docs.map((item) => {
            return {
                id: item.id,
                ...item.data()
            }
        }));
    };

    const createList = async() => {
        const list = prompt("Qual o nome da lista que deseja criar?");

        if (!list) return;
        setIsLoading(true);
        await firebase.firestore().collection('shopping-list').doc().set({
            title: list
        });
        await fetchList();
        setIsLoading(false);
    };

    const addNewItem = async(item: string) => {
        if (!listSelected) return;
        setIsLoading(true);

        const newItemRef = firebase.firestore().collection('shopping-list').doc(listSelected).collection('items').doc();

        const newItem = {
            id: newItemRef.id,
            title: item,
            inCart: false
        };

        const newItems = [...items];
        newItems.push(newItem);
        setItems(newItems);

        const element = document.getElementById('newItem') as any;
        element.value = '';
        element.focus();

        await newItemRef.set(newItem);

        setIsLoading(false);
    };

    const toggleItemInCart = async(item: any) => {
        if (!listSelected) return;
        setIsLoading(true);

        const inCart = !!!item.inCart;

        const newItems = [...items];
        const indexItem = newItems.findIndex((i: any) => i.id == item.id);
        newItems[indexItem].inCart = inCart;
        setItems(newItems);


        try {
            await firebase.firestore().collection('shopping-list').doc(listSelected).collection('items').doc(item.id).set({
                inCart: inCart
            }, {merge: true})
        } catch (err) {
            alert('Ocorreu um erro interno.');
            await fetchItems();
        }
        
        setIsLoading(false);
    };

    const cleanCart = async() => {
        if (!listSelected) return;

        const newItems = items.filter((item: any) => {
            return !item.inCart;
        });
        setItems(newItems);

        setIsLoading(true);
        const inCartItemsSnapshot = await firebase
            .firestore()
            .collection('shopping-list')
            .doc(listSelected)
            .collection('items')
            .where('inCart', '==', true)
            .get();

        const batch = firebase.firestore().batch();
        inCartItemsSnapshot.docs.forEach((item) => {
            batch.delete(item.ref);
        });

        batch.commit();

        setIsLoading(false);
    };

    const removeSelectedList = async() => {
        if (!listSelected) return;

        const confirm = await swal({
            text: 'Você tem certeza que deseja remover?',
            icon: 'warning',
            buttons: true as any,
            dangerMode: true
        });

        if (!confirm) return;

        await firebase
            .firestore()
            .collection('shopping-list')
            .doc(listSelected)
            .delete();

        await swal('Sucesso!', 'Lista foi removida com sucesso.', 'success');
        window.location.reload();
    };

    React.useEffect(() => {
        (async() => {
            await fetchList();
            setIsLoading(false);
        })();
    }, []);

    React.useEffect(() => {
        if (!listSelected) return;
        (async() => {
            setIsLoading(true);
            await fetchItems();
            setIsLoading(false);
        })();
    }, [listSelected]);

    return (
        <>

        {isLoading ? (
            <Tooltip>Carregando...</Tooltip>
        ) : null}
        <div className="top">
            <Button color="black" onClick={() => history.replace('/dashboard')}>Voltar para Dashboard</Button>
        </div>

        <ListMenu items={lists} withCreate={createList} selected={listSelected} onSelect={(item) => setListSelected(item.id)} />

        <div className="items" style={{display: listSelected ? 'flex' : 'none'}}>
            <input id="newItem" className="new-item" placeholder={`Novo item...`} onKeyUp={(event) => {
                if (event.key === 'Enter') {
                    addNewItem(event.currentTarget.value);
                }
            }}></input>

            {items.length < 1 ? (
                <>
                    <h3 className="title">Nenhum item na lista.</h3>

                    {listSelected != 'vh5DUIp2VL052qQdLnxr' ? (
                        <Button color="red" onClick={() => removeSelectedList()}>Remover lista</Button>
                    ) : null}
                    
                </>
            ) : (
                <>
                    <h3 className="title">Para comprar</h3>

                    {items.map((item: any) => {
                        if (item.inCart) return;

                        return (
                            <div className="item" onClick={() => toggleItemInCart(item)}>
                                {item.title}
                            </div>
                        );
                    })}
                </>
            )}
            

            {items.filter((item: any) => item.inCart).length < 1 ? null : (
                <>
                <h3 className="title">No carrinho</h3>
                <Button color="red" onClick={() => cleanCart()}>Limpar</Button>
                {items.map((item: any) => {
                    if (!item.inCart) return;

                    return (
                        <div className="item cart" onClick={() => toggleItemInCart(item)}>
                            {item.title}
                        </div>
                    );
                })}
                </>
            )}
            
        </div>
        </>
    )
};