import React, { createContext, useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import Auth from './components/auth/Auth';
import Welcome from './components/welcome/Welcome';
import Dashboard from './components/dashboard/Dashboard';
import ModuleRender from './modules/ModuleRender';
import { MODULE_ROUTE_PREFIX } from './modules/module';
import 'moment/locale/pt-br'
import 'moment-countdown';
import countdown from 'countdown';
import usePersistentState from './hooks/usePersistentState';
import settings from './shared/settings';
import swal from 'sweetalert';
import { v4 as uuidv4 } from 'uuid';


firebase.initializeApp({
  apiKey: "AIzaSyD91V3XO3CFgi9hp6EzzKRXWyV3aqxVnfY",
  authDomain: "dantascenter-90f4e.firebaseapp.com",
  databaseURL: "https://dantascenter-90f4e.firebaseio.com",
  projectId: "dantascenter-90f4e",
  storageBucket: "dantascenter-90f4e.appspot.com",
  messagingSenderId: "598190359992",
  appId: "1:598190359992:web:606f7572268fcd74707b4d"
});
firebase.auth().languageCode = 'pt-br';

export const UserContext = createContext<firebase.User|null>(localStorage.getItem('last_user') ? JSON.parse(localStorage.getItem('last_user') || '') : null);
export const DarkModeContext = createContext<boolean>(false);
countdown.setLabels(
	' milissegundo| segundo| minuto| hora| dia| semana| mês| ano| década| século| milênio',
	' milissegundos| segundos| minutos| horas| dias| semanas| meses| anos| décadas| séculos| milênios',
	' e ',
	' + ',
  'agora');

const App = () => {
  const [user, setUser] = useState<firebase.User|null>(localStorage.getItem('last_user') ? JSON.parse(localStorage.getItem('last_user') || '') : null);
  const [darkMode, setDarkMode] = usePersistentState<boolean>('dark_mode', false);

  const registerNotification = async() => {
    if (!firebase.messaging.isSupported()) return;

    const messaging = firebase.messaging();
    await (messaging as any).getToken({
      vapidKey: settings.cloudMessaging.vapidToken
    }).then((currentToken: any) => {
      if (currentToken) {
        const registerNotificationToken = firebase.functions().httpsCallable('registerNotificationToken');

        let deviceId = localStorage.getItem('_device_id');
        if (!deviceId) {
          deviceId = uuidv4().toString();
          localStorage.setItem('_device_id', deviceId)
        }

        return registerNotificationToken({
          notificationToken: currentToken,
          deviceId
        });
      } else {
        swal(
          'Notificação recusada!',
          'Não foi possível registrar token de notificação, a solicitação foi recusada.',
          'error'
        )
      }
    }).catch((err: any) => {
      swal(
        'Erro ao registrar notificação.',
        'Ocorreu um erro para registar o cliente de notificação.',
        'error'
      );

      console.error(err);
    });

    messaging.onMessage((payload) => {
      console.log('message', payload);
      swal(
        payload.notification.title || 'Notificação recebida',
        payload.notification.body || 'Você recebeu uma notificação sem conteúdo.',
        payload.notification.alertIcon || 'info'
      )
    }, err => {
      swal(
        'Erro ao receber notificação',
        '',
        'error'
      );
      console.error(err);
    });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      
      if (!user) {
        localStorage.removeItem('last_user');
        return;
      }

      //registerNotification();

      localStorage.setItem('last_user', JSON.stringify(user));
    });
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'F9') {
        setDarkMode(!!!darkMode);
      }
    });
  }, [darkMode, setDarkMode]);

  const AuthRoute = ({children, ...props}: any) => (
    <Route
      {...props}
      render={({location}) => user ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location }
          }}
        />
      )} 
    />
  );

  return (
    <UserContext.Provider value={user}>
      <DarkModeContext.Provider value={darkMode}>
        <Router>
          <Switch>
            <Route path="/login">
              <Auth />
            </Route>

            <AuthRoute path="/dashboard">
              <Dashboard />
            </AuthRoute>

            <AuthRoute path={MODULE_ROUTE_PREFIX}>
              <ModuleRender />
            </AuthRoute>

            <AuthRoute path="/" exact={true}>
              <Welcome />
            </AuthRoute>
          </Switch>
        </Router>
      </DarkModeContext.Provider>  
    </UserContext.Provider>
  );
}

export default App;
