import firebase from 'firebase';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import usePersistentState from '../../../hooks/usePersistentState';
import Button from '../../../uiKit/Button/Button';
import Tooltip from '../../../uiKit/tooltip/Tooltip';
import { Category, Expense, PaymentMethod, Recorrency, Status } from '../models';
import withReactContent from 'sweetalert2-react-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { isFloat, isValidNumber } from 'face-api.js/build/commonjs/utils';
import { Icon, Label } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import _, { isNumber } from 'underscore';
import { DataGrid, GridColDef, GridFilterModel, GridParamsApi, GridRowId, GridRowParams, GridSortModel, GridValueGetterParams, MuiEvent, ptBR, useGridApiRef } from '@mui/x-data-grid';
import { SyntheticEvent } from 'react';
import CardDetails from '../../../uiKit/CardDetails/CardDetails';

export default () => {
    const history = useHistory();
    const [expenses, setExpenses] = React.useState<Array<Expense>>([]);
    const [categories, setCategories] = React.useState<Array<Category>>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [tooltip, setTooltip] = React.useState<string|null>(null);
    const [filter, setFilter] = React.useState<((expenses: Expense[]) => Expense[])|null>(null);

    const fetch = async() => {
        const expensesSnap = await firebase.firestore().collection('finances-expenses').get();

        setExpenses(expensesSnap.docs.map((expense) => ({
            id: expense.id,
            ...expense.data()
        }) as Expense));
    };

    const fetchCategories = async() => {
        const categoriesSnap = await firebase.firestore().collection('finances-expenses-categories').get();

        const categories = categoriesSnap.docs.map((category) => ({
            id: category.id,
            ...category.data()
        }) as Category);

        setCategories(categories);
    };

    React.useEffect(() => {
        (async() => {
            await fetchCategories();
            await fetch();
            setTooltip(null);
        })();
    }, []);

    const changeFilterModel = (model: GridFilterModel) => {
        const filterItem = model.items[0];
        if (!filterItem || !filterItem.value) {
            setFilter(null);
            return;
        }

        if (filterItem.operatorValue == 'contains') {
            setFilter(() => {
                return (expenses: Expense[]) => {
                    return expenses.filter((expense: any) => {
                        if (!filterItem.columnField) return true;
                        return expense[filterItem.columnField].includes(filterItem.value);
                    });
                }
            });
            return;
        }

        setFilter(null);
    };

    const getTotalMonth = () => {
        let expensesToCalculate = expenses;
        if (filter) {
            expensesToCalculate = filter(expensesToCalculate);
        }

        return expensesToCalculate.reduce((previus, current): number => {
            if (current.status !== Status.ACTIVE) return previus;

            if (current.recorrency == Recorrency.MONTHLY) {
                return previus + parseFloat(current.value);
            }

            return previus;
        }, 0);
    };

    const getTotalYear = () => { 
        let expensesToCalculate = expenses;
        if (filter) {
            expensesToCalculate = filter(expensesToCalculate);
        }

        return expensesToCalculate.reduce((previus, current): number => {
            if (current.status !== Status.ACTIVE) return previus;

            if (current.recorrency == Recorrency.MONTHLY) {
                return previus + parseFloat(current.value)*12;
            }

            if (current.recorrency == Recorrency.YEARLY) {
                return previus + parseFloat(current.value);
            }

            return previus;
        }, 0); 
    };

    const removeExpense = async(expense: Expense) => {
        const confirm = await Swal.fire({
            title: 'Deseja mesmo?',
            html: `Realmente deseja remover <strong>${expense.description}</strong>?`,
            showCancelButton: true,
            cancelButtonText: 'Não remover',
            confirmButtonText: `Sim, quero remover.`
        });

        if (!confirm.isConfirmed) return;

        setTooltip('Removendo...');
        await firebase.firestore().collection('finances-expenses').doc(expense.id).delete();
        await fetch();
        setTooltip(null);
    };

    const saveNewValue = async(transaction: Expense, newValue: number) => {
        setTooltip('Salvando...');
        await firebase.firestore().collection('finances-expenses').doc(transaction.id).set({
            value: newValue
        }, {merge: true});
        await fetch();
        setTooltip(null);
    };

    const saveNewDescription = async (transaction: Expense, description: string) => {
        setTooltip('Salvando...');
        await firebase.firestore().collection('finances-expenses').doc(transaction.id).set({
            description
        }, {merge: true});
        await fetch();
        setTooltip(null);
    };

    const getCategoriesToForm = async() => { 
        const categoriesForm: any = {};
        
        categories.forEach((category) => {
            categoriesForm[category.title] = category.title;
        }); 

        return categoriesForm; 
    };

    const getCategoryColor = (category: string): any => {
        const categoryFounded = _.findWhere(categories, {title: category});
        if (!categoryFounded) return 'grey';

        return categoryFounded.color;
    };

    const changeCategory = async(expense: Expense) => {
        const newCategory = await Swal.fire({
            title: 'Nova categoria',
            input: 'select',
            inputOptions: getCategoriesToForm(),
            inputValue: expense.category,
            showCancelButton: true,
            cancelButtonText: 'Não mudar',
            confirmButtonText: 'Alterar categoria'
        });

        if (!newCategory.isConfirmed) return;
        setIsLoading(true);
        await firebase.firestore().collection('finances-expenses').doc(expense.id).set({
            category: newCategory.value
        }, {merge: true});
        await fetch();
        setIsLoading(false);
    };

    const changePaymentMethod = async(expense: Expense) => {
        const newPaymentMethod = await Swal.fire({
            title: 'Nova categoria',
            input: 'select',
            inputOptions: {
                [PaymentMethod.CARD_CREDIT]: PaymentMethod.CARD_CREDIT,
                [PaymentMethod.MONEY]: PaymentMethod.MONEY
            },
            inputValue: expense.paymentMethod,
            showCancelButton: true,
            cancelButtonText: 'Não mudar',
            confirmButtonText: 'Alterar categoria'
        });

        if (!newPaymentMethod.isConfirmed) return;
        setIsLoading(true);
        await firebase.firestore().collection('finances-expenses').doc(expense.id).set({
            paymentMethod: newPaymentMethod.value
        }, {merge: true});
        await fetch();
        setIsLoading(false);

    };

    const changeDescription = async(expense: Expense) => {
        const newDescription = await Swal.fire({
            title: 'Nova descrição',
            input: 'text',
            inputValue: expense.description,
            showCancelButton: true,
            cancelButtonText: 'Não mudar',
            confirmButtonText: 'Alterar'
        });

        if (!newDescription.isConfirmed) return;
        expense.description = newDescription.value;
        await firebase.firestore().collection('finances-expenses').doc(expense.id).set({
            description: newDescription.value
        }, {merge: true});
    };

    const toggleStatus = async(expense: Expense) => {
        const newStatus = expense.status === Status.ACTIVE ? Status.CANCELED : Status.ACTIVE;

        setIsLoading(true);
        await firebase.firestore().collection('finances-expenses').doc(expense.id).set({
            status: newStatus
        }, {merge: true});
        await fetch();
        setIsLoading(false);
    };

    const changeValue = async(expense: Expense) => {
        const newValue = await Swal.fire({
            title: `Novo valor ${expense.recorrency === Recorrency.MONTHLY ? 'Mensal' : 'Anual'}`,
            input: 'text',
            inputValue: parseFloat(expense.value),
            showCancelButton: true,
            cancelButtonText: 'Não mudar',
            confirmButtonText: 'Alterar'
        });

        if (!newValue.isConfirmed) return;

        if (!isNumber(parseFloat(newValue.value))) {
            swal('Ops!', 'Precisa informar um número válido', 'error');
            return;
        }

        setIsLoading(true);
        await firebase.firestore().collection('finances-expenses').doc(expense.id).set({
            value: newValue.value.replace(',', '.')
        }, {merge: true});
        await fetch();
        setIsLoading(false);
    };

    const createItem = async() => {
        const result: SweetAlertResult = await Swal.mixin({
            confirmButtonText: 'Proximo &rarr;',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            progressSteps: ['1', '2', '3', '4']
        }).queue([
            {
                title: 'Descrição do item',
                input: 'text'
            },
            {
                title: 'Valor',
                input: 'text'
            },
            {
                title: 'Categoria',
                input: 'select',
                inputOptions: getCategoriesToForm()
            },
            {
                title: 'Metodo de pagamento',
                input: 'select',
                inputOptions: {
                    [PaymentMethod.CARD_CREDIT]: PaymentMethod.CARD_CREDIT,
                    [PaymentMethod.MONEY]: PaymentMethod.MONEY
                }
            },
            {
                title: 'Recorrencia',
                input: 'select',
                inputOptions: {
                    [Recorrency.MONTHLY]: Recorrency.MONTHLY,
                    [Recorrency.YEARLY]: Recorrency.YEARLY 
                }
            }
        ]);

        if (result.dismiss) return;
        
        const expense: Expense = {
            description: result.value[0] as string,
            value: result.value[1].replace(',', '.') as string,
            status: Status.ACTIVE,
            category: result.value[2] as string,
            paymentMethod: result.value[3] as PaymentMethod,
            recorrency: result.value[4] as Recorrency
        };

        await firebase.firestore().collection('finances-expenses').doc().set(expense);
        fetch();
        
    };

    if (isLoading) {
        return (
            <Tooltip>Carregando...</Tooltip>
        );
    }

    const columns: GridColDef[] = [
        { 
            field: 'description',
            headerName: 'Nome',
            flex: 1.5,
            renderCell: (params) => {
                return (
                    <div
                        className="editableTable"
                        onClick={() => changeDescription(params.row as Expense)}
                    >{params.value}</div>
                )
            },
            editable: true
        },
        { 
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => {
                if (params.value === Status.ACTIVE) {
                    return <Label color="green" onClick={() => toggleStatus(params.row as Expense)}>Ativo</Label>
                }

                return <Label color="red" onClick={() => toggleStatus(params.row as Expense)}>Cancelado</Label>
            },
        },
        {
            field: 'category',
            headerName: 'Categoria',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Label 
                        color={getCategoryColor(params.value as string)}
                        onClick={() => changeCategory(params.row as Expense)}
                    >
                        {params.value}
                    </Label>
                );
            }
        },
        {
            field: 'paymentMethod',
            headerName: 'Meio de pagamento',
            flex: 0.5,
            renderCell: (params) => {
                if (params.value === PaymentMethod.CARD_CREDIT) {
                    return (
                        <Label color="red" onClick={() => changePaymentMethod(params.row as Expense)}>Cartão</Label>
                    );
                }

                if (!params.value) {
                    return <Label color="yellow" onClick={() => changePaymentMethod(params.row as Expense)}>SEM</Label>

                }

                return <Label color="green" onClick={() => changePaymentMethod(params.row as Expense)}>Dinheiro</Label>
            }
        },
        {
            field: 'monthly',
            headerName: 'Custo mensal',
            flex: 0.5,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                let value1: number = cellParams1.api.getCellValue(cellParams1.id, 'value');
                let value2: number = cellParams2.api.getCellValue(cellParams2.id, 'value');

                if (cellParams1.api.getCellValue(cellParams1.id, 'recorrency') !== Recorrency.MONTHLY) {
                    value1 = 0;
                }

                if (cellParams2.api.getCellValue(cellParams2.id, 'recorrency') !== Recorrency.MONTHLY) {
                    value2 = 0;
                }

                return value1 - value2;
            },
            renderCell: (params) => {
                if (params.row.recorrency === Recorrency.YEARLY) {
                    return 'N/A';
                }
                
                const value = parseFloat(params.row.value as string).toLocaleString('pt-BR', {currency: 'BRL', style: 'currency'})    

                return (
                    <div onClick={() => changeValue(params.row as Expense)}>{value}</div>
                );
            }
        },
        {
            field: 'yearly',
            headerName: 'Custo Anual',
            flex: 0.5,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                let value1: number = cellParams1.api.getCellValue(cellParams1.id, 'value');
                let value2: number = cellParams2.api.getCellValue(cellParams2.id, 'value');

                if (cellParams1.api.getCellValue(cellParams1.id, 'recorrency') == Recorrency.MONTHLY) {
                    value1 = value1*12;
                }

                if (cellParams2.api.getCellValue(cellParams2.id, 'recorrency') == Recorrency.MONTHLY) {
                    value2 = value2*12;
                }

                return value1 - value2;
            },
            renderCell: (params) => {
                if (params.row.recorrency === Recorrency.MONTHLY) {
                    return (parseFloat(params.row.value as string)*12).toLocaleString('pt-BR', {currency: 'BRL', style: 'currency'})    
                }
                
                const value = (parseFloat(params.row.value as string)).toLocaleString('pt-BR', {currency: 'BRL', style: 'currency'});

                return (
                    <div onClick={() => changeValue(params.row as Expense)}>{value}</div>
                );
            }
        },
        {
            field: 'delete',
            headerName: ' ',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Button color="red" full onClick={() => removeExpense(params.row as Expense)}>Remover</Button>
                )
            }
        },
        {
            field: 'recorrency',
            headerName: 'Recorrencia',
            hide: true
        }
    ];

    return (
        <div>

            {tooltip !== null ? (
                <Tooltip>{tooltip}</Tooltip>
            ) : null}

            <Button color="black" style={{position: 'absolute', margin: 15, right: 0}} onClick={() => history.goBack()}>Voltar</Button>

            <div className="dashboard">
                <CardDetails color="green" size="small" onClick={() => createItem()}>
                    <h2>Novo</h2>
                </CardDetails>

                <CardDetails color="red" size="small">
                    <h2>{getTotalMonth().toLocaleString('pt-BR', {currency: 'BRL', style: 'currency'})}</h2>
                    <h3>Total por mês</h3>
                </CardDetails>

                <CardDetails color="red" size="small">
                    <h2>{getTotalYear().toLocaleString('pt-BR', {currency: 'BRL', style: 'currency'})}</h2>
                    <h3>Total por ano</h3>
                </CardDetails>
            </div>

            <div className="container">        

                <div style={{ height: '80vh', width: '60%', paddingBottom: 100 }}>
                    <DataGrid
                        autoHeight
                        columns={columns}
                        rows={expenses}
                        localeText={(ptBR as any).props.MuiDataGrid.localeText}
                        editMode="row"
                        onFilterModelChange={changeFilterModel}
                    />
                </div>                
            </div>
        </div>
    );
};