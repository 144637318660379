import firebase from 'firebase';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router';
import { Card, Icon, Item } from 'semantic-ui-react';
import swal from 'sweetalert';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { isNumber } from 'underscore';
import Button from '../../../uiKit/Button/Button';
import CardDetails from '../../../uiKit/CardDetails/CardDetails';
import ListMenu from '../../../uiKit/ListMenu/ListMenu';
import Tooltip from '../../../uiKit/tooltip/Tooltip';
import { Budget } from '../models';
import './budgets.scss';

export default () => {
    const history = useHistory();
    const [budgetSelected, setBudgetSelected] = React.useState<Budget|null>(null);
    const [budgets, setBudgets] = React.useState<Budget[]>([]);
    const [tooltip, setTooltip] = React.useState<string|null>(null);

    const fetch = async() => {
        const budgetsSnap = await firebase.firestore().collection('finances-budgets').get();

        setBudgets(budgetsSnap.docs.map((budget) => ({
            id: budget.id,
            ...budget.data()
        } as Budget)));
    };

    React.useEffect(() => {
        (async() => {
            setTooltip('Carregando...');
            await fetch();
            setTooltip(null);
        })();
    }, []);

    const createBudget = async() => {
        const budget: SweetAlertResult = await Swal.mixin({
            confirmButtonText: 'Proximo &rarr;',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            progressSteps: ['1', '2', '3']
        }).queue([
            {
                title: 'Título do Orçamento',
                input: 'text'
            },
            {
                title: 'Para quando?',
                html: '<input id="calendar" type="date" class="swal2-input" /> <br /> <small>Deixe em branco para não definir data.</small>',
                preConfirm: () => {
                    return (document.getElementById('calendar') as any).value
                }
            },
            {
                title: 'Valor inicial (R$)',
                input: 'text'
            }
        ]);

        if (budget.dismiss) return;

        if (!isNumber(parseFloat(budget.value[1]))) {
            swal('Ops', 'A meta precisa ser um valor númerico válido', 'error');
            return;
        }

        if (!isNumber(parseFloat(budget.value[2]))) {
            swal('Ops', 'O valor inicial precisa ser um valor númerico válido', 'error');
            return;
        }

        const newBudget: Budget = {
            title: budget.value[0],
            date: budget.value[1],
            amount: budget.value[2],
            goals: []
        };

        setTooltip('Criando...');

        await firebase.firestore().collection('finances-budgets').doc().set(newBudget);
        await fetch();
        setTooltip(null);

    };

    const getGoalValue = (budget: Budget) => {
        if (!budget.goals) return 0;

        return budget.goals.reduce((previus, current) => {
            return previus + parseFloat(current.price);
        }, 0);
    };

    const createGoal = async() => {
        if (!budgetSelected || !budgetSelected.id) return;
        const goal: SweetAlertResult = await Swal.mixin({
            confirmButtonText: 'Proximo &rarr;',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            progressSteps: ['1', '2']
        }).queue([
            {
                title: 'Título do item na meta',
                input: 'text'
            },
            {
                title: 'Valor do item',
                input: 'text'
            }
        ]);

        if (goal.dismiss) return;

        if (!isNumber(parseFloat(goal.value[1]))) {
            swal('Ops', 'A meta precisa ser um valor númerico válido', 'error');
            return;
        }

        budgetSelected.goals.push({
            title: goal.value[0],
            price: goal.value[1]
        });

        setTooltip('Salvando...');
        await firebase.firestore().collection('finances-budgets').doc(budgetSelected.id).set({
            goals: budgetSelected.goals
        }, {merge: true});
        setTooltip(null);
    };

    return (
        <>
            {tooltip ? (
                <Tooltip>{tooltip}</Tooltip>
            ) : null}

            <Button color="black" style={{margin: 15, right: 0}} onClick={() => history.replace('/module/finance')}>Voltar para Dashboard</Button>

            <div className="container">
                <ListMenu
                    items={budgets as any}
                    withCreate={() => createBudget()}
                    selected={budgetSelected ? (budgetSelected as any).id : null}
                    onSelect={(selected: any) => setBudgetSelected(selected)}
                />

                {budgetSelected != null ? (
                    <>
                    <div className="dashboard">
                        
                        <CardDetails color="green" size="small">
                            <h2>{parseFloat(budgetSelected.amount).toLocaleString('pt-br', {currency: 'BRL', style: 'currency'})}</h2>
                            <h3>Valor Atual</h3>
                        </CardDetails>

                        <CardDetails color="red" size="small">
                            <p>falta {(getGoalValue(budgetSelected)-parseFloat(budgetSelected.amount)).toLocaleString('pt-br', {currency: 'BRL', style: 'currency'})}</p>
                            <h2>{getGoalValue(budgetSelected).toLocaleString('pt-br', {currency: 'BRL', style: 'currency'})}</h2>
                            <h3>Meta</h3>
                        </CardDetails>

                        <CardDetails color="black" size="small">
                            <p>{moment(budgetSelected.date).fromNow()}</p>
                            <h2>{
                                (
                                    ((getGoalValue(budgetSelected)-parseFloat(budgetSelected.amount)) / moment(budgetSelected.date).diff(moment(), 'months', false))
                                        .toLocaleString('pt-br', {currency: 'BRL', style: 'currency'}))}</h2>
                            <h3>Valor por mês</h3>
                        </CardDetails>
                    </div>

                    <hr />

                    <div className="budget-goals">
                        <div className="dashboard">
                            <CardDetails color="green" size="small" onClick={() => createGoal()}>
                                <h2>Novo item</h2>
                            </CardDetails>
                            {budgetSelected.goals.map((goal) => (
                                <CardDetails color="blue" size="small">
                                    <h2>{parseFloat(goal.price).toLocaleString('pt-br', {currency: 'BRL', style: 'currency'})}</h2>
                                    <p>{goal.title}</p>
                                </CardDetails>
                            ))}
                        </div>
                    </div>
                    </>

                ) : null}
            </div>
        </>
    );
};