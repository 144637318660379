import * as React from 'react';
import './Notes.scss';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { UserContext, DarkModeContext } from '../../App';
import usePersistentState from '../../hooks/usePersistentState';
import { ProfileType } from '../../models/profile.model';
import { Category, Categories, Notes } from './models';
import { MODULE_ROUTE_PREFIX } from '../module';
import moment from 'moment';
import Button from '../../uiKit/Button/Button';

export default () => {
    const history = useHistory();
    const [notes, setNotes] = React.useState<Notes|null>(null);
    const [category, setCategory] = React.useState<Category|null>();
    const [categories, setCategories] = React.useState<Categories|null>(null);
    const [newCategory, setNewCategory] = React.useState<string|null>(null);
    const [creating, setCreating] = React.useState<boolean>(false);
    const [profile] = usePersistentState<ProfileType|null>('profile', null);
    const user = React.useContext(UserContext);
    const darkMode = React.useContext(DarkModeContext);
    
    React.useEffect(() => {
        let mounted = true;
        const fetchData = async() => {
            if (!user) return;
            const categories = (await firebase
                .firestore()
                .collection('notes-categories')
                .where('owner', '==', user.uid)
                .where('profile', '==', profile)
                .get()
            );
            if (categories.empty) {
                if (mounted) setCategories([]);
                if (mounted) setNotes([]);
            }

            if (mounted) setCategories(categories.docs.map((categorie) => {
                return {
                    id: categorie.id,
                    ...categorie.data()
                };
            }) as Categories);
            
            const category = categories.docs[0];
            if (mounted) setCategory({
                ...(category.data() as Category),
                id: category.id
            });

            const notes = (await firebase
                .firestore()
                .collection('notes')
                .where('owner', '==', user.uid)
                .where('category', '==', category.id)
                .get()
            ).docs;

            if (mounted) setNotes((notes.sort((a, b) => b.data().modified_at - a.data().modified_at).map((note) => {
                return {
                    id: note.id,
                    ...note.data()
                }
            })) as Notes);
        };

        fetchData();

        return () => { mounted = false }
    }, [user, profile]);

    const addCategory = () => {
        setNewCategory('');
        setTimeout(() => {
            const element = document.getElementById('newCategory');
            element?.focus();
            element?.addEventListener('keydown', async(event) => {
                if (!user) return;
                if (event.key === 'Enter') {
                    const categoryNew = {
                        title: element.innerText,
                        owner: user.uid,
                        profile
                    }

                    firebase.firestore().collection('notes-categories').add(categoryNew);
                    setCategories((state: any) => [
                        ...(state || []),
                        categoryNew
                    ]);

                    setNewCategory(null);

                    event.preventDefault();
                    return;
                }

                if (event.key === 'Escape') {
                    setNewCategory(null);
                }
            });

            element?.addEventListener('focusout', () => {
                setNewCategory(null);
            });
        }, 100);
    };

    const selectCategory = async(category: Category) => {
        setCategory(category);
        setNotes(null);

        const notes = (await firebase
            .firestore()
            .collection('notes')
            .where('category', '==', category.id)
            .get()).docs;

        setNotes(notes.sort((a, b) => b.data().modified_at - a.data().modified_at).map((note) => {
            return {
                id: note.id,
                ...note.data()
            }
        }) as Notes);
    };

    const createNote = async() => {
        if (!category || !user) return;
        setCreating(true);
        const noteCreated = await firebase
            .firestore()
            .collection('notes')
            .add({
                title: '',
                content: '',
                modified_at: new Date(),
                owner: user.uid,
                category: category.id
            });
        history.push(`${MODULE_ROUTE_PREFIX.replace(':moduleId', 'notes')}/view/${noteCreated.id}`)
    };

    if (creating) {
        return (
            <p>Criando note...</p>
        );
    }

    return (
        <div className={`notes-module notes-list ${darkMode ? 'dark' : null}`}>
            <div className="top">
                <h1>Anotações</h1>
                <Button color="black" onClick={() => history.replace('/dashboard')}>Voltar para Dashboard</Button>
                &emsp;
                {category ? (
                    <Button color="green" onClick={createNote}>Nova nota em {category.title}</Button>
                ) : null}
            </div>

            <div className="categories">
                {
                    categories?.map((categoryItem) => (
                        <>
                            <button key={categoryItem.id} className={(categoryItem.id === category?.id) ? 'selected' : ''} onClick={() => selectCategory(categoryItem)}>{categoryItem.title}</button>
                        </>
                    ))
                }
                {
                    newCategory === '' ? (
                        <>
                            <span id="newCategory" contentEditable={true} placeholder="Nome da categoria"></span>
                        </>
                    ) : (
                        <button onClick={() => addCategory()}>+</button>
                    )
                }
            </div>

            <div className="notes">
                {
                    (notes == null) ? (
                        <p>Carregando...</p>
                    ) : null
                }
                {
                    (notes && notes.length < 1) ? (
                        <p>Nenhuma nota em {category?.title || 'categoria'}</p>
                    ) : null
                }
                {
                    notes?.map((note) => (
                        <div key={note.id}>
                            <div className="note">
                                <h2 onClick={() => history.push(`${MODULE_ROUTE_PREFIX.replace(':moduleId', 'notes')}/view/${note.id}`)}>{note.title || 'Sem título'}</h2>
                                <p>Ultima modificação <span title={moment(note.modified_at.toMillis()).format('LLLL')}>{moment(note.modified_at.toMillis()).fromNow()}</span></p>
                            </div>
                            <hr />
                        </div>
                    ))
                }
            </div>
        </div>
    )
};
