export enum Recorrency {
    MONTHLY = 'mensal',
    YEARLY = 'anual'
}

export enum Status {
    ACTIVE = 'active',
    CANCELED = 'canceled'
}

export enum PaymentMethod {
    CARD_CREDIT = 'Cartão de crédito',
    MONEY = 'Dinheiro'
}

export type Category = {
    id?: string,
    title: string,
    color: string
}

export type Earning = {
    id?: string,
    description: string,
    recorrency: Recorrency,
    value: string
}

export type Expense = {
    id?: string,
    description: string,
    status: Status,
    category: string,
    recorrency: Recorrency,
    paymentMethod: PaymentMethod,
    value: string
}

export type BudgetGoal = {
    title: string,
    price: string
};

export type Budget = {
    id?: string,
    title: string,
    date: string,
    amount: string,
    goals: BudgetGoal[]
};

