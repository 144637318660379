import firebase from 'firebase';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import usePersistentState from '../../../hooks/usePersistentState';
import Button from '../../../uiKit/Button/Button';
import Tooltip from '../../../uiKit/tooltip/Tooltip';
import { Earning, Recorrency } from '../models';
import withReactContent from 'sweetalert2-react-content';
import './earnings.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { isFloat, isValidNumber } from 'face-api.js/build/commonjs/utils';
import { Icon } from 'semantic-ui-react';

export default () => {
    const history = useHistory();
    const [earnings, setEarnings] = usePersistentState<Array<Earning>>('earnings', []);
    const [isLoading, setIsLoading] = React.useState(false);
    const [tooltip, setTooltip] = React.useState<string|null>(null);
    const [currentMonth, setCurrentMonth] = React.useState((moment()));

    const fetchEarnings = async() => {
        const earningsSnap = await firebase.firestore().collection('finances-earnings').get();
        setEarnings(earningsSnap.docs.map((earning) => ({
            id: earning.id,
            ...earning.data()
        } as Earning)));
    };

    React.useEffect(() => {
        (async() => {
            setEarnings([]);
            setTooltip('Carregando...');
            await fetchEarnings();
            setTooltip(null);
        })();

    }, [currentMonth]);

    const getTotalMonth = () => {
        return earnings.reduce((previus, current): number => {
            if (current.recorrency == Recorrency.MONTHLY) {
                return previus + parseFloat(current.value);
            }

            return previus;
        }, 0);
    };

    const getTotalYear = () => {
        return earnings.reduce((previus, current): number => {
            if (current.recorrency == Recorrency.MONTHLY) {
                return previus + parseFloat(current.value)*12;
            }

            if (current.recorrency == Recorrency.YEARLY) {
                return previus + parseFloat(current.value);
            }

            return previus;
        }, 0);
    };

    const removeEarning = async(earning: Earning) => {
        const confirm = await Swal.fire({
            title: 'Deseja mesmo?',
            html: `Realmente deseja remover <strong>${earning.description}</strong>?`,
            showCancelButton: true,
            cancelButtonText: 'Não remover',
            confirmButtonText: `Sim, quero remover.`
        });

        if (!confirm.isConfirmed) return;

        setTooltip('Removendo...');
        await firebase.firestore().collection('finances-earnings').doc(earning.id).delete();
        await fetchEarnings();
        setTooltip(null);
    };

    const saveNewValue = async(transaction: Earning, newValue: number) => {
        setTooltip('Salvando...');
        await firebase.firestore().collection('finances-earnings').doc(transaction.id).set({
            value: newValue
        }, {merge: true});
        await fetchEarnings();
        setTooltip(null);
    };

    const saveNewDescription = async (transaction: Earning, description: string) => {
        setTooltip('Salvando...');
        await firebase.firestore().collection('finances-earnings').doc(transaction.id).set({
            description
        }, {merge: true});
        await fetchEarnings();
        setTooltip(null);
    };

    const createTransaction = async() => {
        const result: SweetAlertResult = await Swal.mixin({
            confirmButtonText: 'Proximo &rarr;',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            progressSteps: ['1', '2', '4']
        }).queue([
            {
                title: 'Descrição do item',
                input: 'text'
            },
            {
                title: 'Valor',
                input: 'text'
            },
            {
                title: 'Recorrencia',
                input: 'select',
                inputOptions: {
                    [Recorrency.MONTHLY]: Recorrency.MONTHLY,
                    [Recorrency.YEARLY]: Recorrency.YEARLY 
                }
            }
        ]);

        if (result.dismiss) return;
        
        const earning: Earning = {
            description: result.value[0] as string,
            value: result.value[1] as string,
            recorrency: result.value[2] as Recorrency
        };

        await firebase.firestore().collection('finances-earnings').doc().set(earning);
        fetchEarnings();
        
    };

    if (isLoading) {
        return (
            <Tooltip>Carregando...</Tooltip>
        );
    }

    return (
        <div className="financeEarningsPage">

            {tooltip !== null ? (
                <Tooltip>{tooltip}</Tooltip>
            ) : null}

            <Button color="black" style={{position: 'absolute', margin: 15, right: 0}} onClick={() => history.goBack()}>Voltar</Button>



            <div className="container">        
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Ganho Mensal</th>
                            <th>Ganho Anual</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {earnings.map((earning: Earning) => (
                            <tr key={earning.id}>
                                <td
                                    contentEditable="true"
                                    onBlur={(event) => {
                                        const newName = event.currentTarget.innerText;
                                        if (newName !== earning.description) saveNewDescription(earning, newName);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            event.currentTarget.blur();
                                        }
                                    }}
                                >{earning.description}</td>

                                <td
                                    contentEditable={earning.recorrency == Recorrency.MONTHLY}
                                    onFocus={(event) => {
                                        event.currentTarget.innerText = `${earning.value}`
                                    }}
                                    onBlur={(event) => {
                                        let newValue = parseFloat(parseFloat(event.currentTarget.innerText).toFixed(2));
                                        if (newValue == NaN) newValue = parseFloat(earning.value);
                                        event.currentTarget.innerText = newValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        if (newValue != parseFloat(earning.value)) saveNewValue(earning, newValue);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            event.currentTarget.blur();
                                        }
                                    }}
                                >
                                    {earning.recorrency == Recorrency.MONTHLY ? (
                                        parseFloat(earning.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                    ) : "N/A"}
                                    
                                </td>


                                <td
                                    contentEditable={earning.recorrency == Recorrency.YEARLY}
                                    onFocus={(event) => {
                                        event.currentTarget.innerText = `${earning.value}`
                                    }}
                                    onBlur={(event) => {
                                        let newValue = parseFloat(parseFloat(event.currentTarget.innerText).toFixed(2));
                                        if (newValue == NaN) newValue = parseFloat(earning.value);
                                        event.currentTarget.innerText = newValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        if (newValue != parseFloat(earning.value)) saveNewValue(earning, newValue);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            event.currentTarget.blur();
                                        }
                                    }}
                                >
                                    {earning.recorrency == Recorrency.YEARLY ? (
                                        parseFloat(earning.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                    ) : (
                                        (parseFloat(earning.value)*12).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                    )}
                                    
                                </td>

                                <td className="remove">
                                    <div onClick={() => removeEarning(earning)}>Remover</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                    <tfoot>
                        <tr>
                            <th><Button color="green" onClick={createTransaction}>Adicionar</Button></th>
                            <th>{getTotalMonth().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</th>
                            <th>{getTotalYear().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};