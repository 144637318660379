import { ChangeHistory, Home, Pause, PermMedia, PlayArrow, SkipNext, SkipPrevious } from '@material-ui/icons';
import { Connection, createConnection, createLongLivedTokenAuth, ERR_INVALID_AUTH, getAuth, HassEntity, servicesColl, subscribeEntities, subscribeServices } from 'home-assistant-js-websocket';
import * as React from 'react';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Button from '../../uiKit/Button/Button';
import './dashwall.scss';
import NoSleep from 'nosleep.js';
import * as faceapi from 'face-api.js';

const HASS_URL = 'https://ha.rafaeldantas.me';
let connection: Connection;

export default () => {
    const [temperature, setTemperature] = React.useState(0);
    const [weatherState, setWeatherState] = React.useState('');
    const [spotifyState, setSpotifyState] = React.useState<HassEntity>();
    const [started, setStarted] = React.useState(false);
    const [screensaver, setScreensaver] = React.useState(false);

    React.useEffect(() => {
        (async() => {
            const auth = createLongLivedTokenAuth(
                HASS_URL,
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiI1NzM5MTM0Zjg0MTg0ZTNjYjE0MmQ5YzIxOTk3NGYwOSIsImlhdCI6MTYyMzAzMDY2MywiZXhwIjoxOTM4MzkwNjYzfQ.qDmmg8rO22tfktaLrX2-6D4-P8JnJSsk7gkk8ju_H88"
            );

            connection = await createConnection({auth});

            subscribeEntities(connection, (state: any) => {
                if (state['weather.casa']) {
                    setTemperature(state['weather.casa'].attributes?.temperature);
                    setWeatherState(state['weather.casa'].state);
                }

                if (state['media_player.spotify_rafael_dantas']) {
                    setSpotifyState(state['media_player.spotify_rafael_dantas']);
                }
            });
        })();
    }, []);

    const toggleFullScreen = () => {
        let doc: any = window.document;
        let docEl: any = doc.documentElement;
    
        let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
        let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
    
        if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            requestFullScreen.call(docEl);
        }  else {
            cancelFullScreen.call(doc);
        }
    }

    const start = async() => {
        const noSleep = new NoSleep();
        noSleep.enable();
        toggleFullScreen();
        setStarted(true);

        let screensaverTimeout: NodeJS.Timeout;

        const screensaverDisable = () => {
            setScreensaver(false);
            if (screensaverTimeout) clearTimeout(screensaverTimeout);
            screensaverTimeout = setTimeout(() => setScreensaver(true), 3000);
        };
        //screensaverDisable();

        //document.addEventListener("mousemove", screensaverDisable);
        //document.addEventListener("keydown", screensaverDisable);
        //document.addEventListener("", screensaverDisable);

        console.log('iniciando o roleee');
        try {
            const videoElement = document.createElement("video");
            const stream = await navigator.mediaDevices.getUserMedia({ 
                video: {
                    facingMode: 'user'
                }
            });
            videoElement.autoplay = true;
            videoElement.srcObject = stream;
            await faceapi.loadTinyFaceDetectorModel('/models');
            

            console.log('Before');
            faceapi.detectAllFaces(videoElement, new faceapi.TinyFaceDetectorOptions())
                .then((detections) => {
                    console.log('after');
                    detections.forEach((detection) => {
                        console.log(detection);
                        console.log(detection.score);
                    });

                    return detections;
                });
            
        } catch (err) {
            swal('Ops, vídeo não carregado', 'Não foi possível carregar o video.', 'error');
            alert(err);
            console.error(err);
        }
    };

    const getWeatherStateLabel = (label: string) => {
        return ({
            fog: 'Com neova'
        } as any)[label];
    }

    const mediaSpotifyService = async(service: string) => {
        await connection.sendMessagePromise({
            type: 'call_service',
            domain: 'media_player',
            service: service,
            service_data: {
                entity_id: spotifyState?.entity_id
            }
        });
    };

    const changeDevice = async(uri = '') => {
        const devicesResponse: any = await connection.sendMessagePromise({
            type: 'spotcast/devices'
        });

        const devices: any = {};
        devicesResponse.devices.forEach((device: any) => {
            devices[device.id] = device.name;
        });

        const device = await Swal.fire({
            text: 'Escolha em qual dispositivo começar a reproduzir.',
            input: 'select',
            inputOptions: devices
        });

        if (device.isDismissed) return;

        await connection.sendMessagePromise({
            type: 'call_service',
            domain: 'spotcast',
            service: 'start',
            service_data: {
                uri,
                spotify_device_id: device.value
            }
        });
    };

    const selectMedia = async() => {
        const categories: any = await connection.sendMessagePromise({
            type: 'media_player/browse_media',
            entity_id: 'media_player.spotify_rafael_dantas',
            media_content_type: "categories", 
            media_content_id: "categories"
        });

        let continueExploreMedia = true;
        let categorieSelected: any = null;
        let uri: string|null = null;
        const SwalReact = withReactContent(Swal);
        do {
            if (!categorieSelected) {
                const exploreMedia = await SwalReact.fire({
                    title: "Categorias",
                    customClass: {
                        container: 'media-spotify-popup'
                    },
                    width: '100%',
                    html: (
                        <div className="categories-spotify">
                            {categories.children.map((category: any) => (
                                <div
                                    className="card categorie-card"
                                    style={{backgroundImage: `url("${category.thumbnail}")`}}
                                    onClick={() => {
                                        categorieSelected = {
                                            media_content_id: category.media_content_id,
                                            media_content_type: category.media_content_type
                                        };

                                        SwalReact.clickConfirm();
                                    }}
                                >
                                    <h4>{category.title}</h4>
                                </div>
                            ))}
                        </div>
                    )
                });

                continueExploreMedia = !exploreMedia.isDismissed;
            }

            if (categorieSelected) {
                const media: any = await connection.sendMessagePromise({
                    type: 'media_player/browse_media',
                    entity_id: 'media_player.spotify_rafael_dantas',
                    media_content_type: categorieSelected.media_content_type, 
                    media_content_id: categorieSelected.media_content_id
                });

                const exploreMedia = await SwalReact.fire({
                    title: "Escolha uma playlist",
                    customClass: {
                        container: 'media-spotify-popup'
                    },
                    width: '100%',
                    showConfirmButton: false,
                    html: (
                        <>
                        <Button full={true} color="black" onClick={() => {
                            categorieSelected = null;
                            SwalReact.clickConfirm();
                        }}>Voltar</Button>
                        <div className="categories-spotify">
                            {media.children.map((category: any) => (
                                <div
                                    className="card categorie-card"
                                    style={{backgroundImage: `url("${category.thumbnail}")`}}
                                    onClick={() => {
                                        continueExploreMedia = false;
                                        uri = category.media_content_id;
                                        SwalReact.clickConfirm();
                                    }}
                                >
                                    <h4>{category.title}</h4>
                                </div>
                            ))}
                        </div>
                        </>
                    )
                });

                if (exploreMedia.isDismissed) categorieSelected = null;
            }
        } while(continueExploreMedia);

        if (!uri) return;

        if (spotifyState?.state === 'idle') {
            changeDevice(uri);
            return;
        }

        await connection.sendMessagePromise({
            type: 'call_service',
            domain: 'media_player',
            service: 'play_media',
            service_data: {
                media_content_id: uri,
                media_content_type: 'playlist',
                entity_id: spotifyState?.entity_id
            }
        });
    };

    if (!started) {
        return (
            <div className="start-button">
                <Button onClick={() => start()}>Iniciar Dash</Button>
            </div>
        )
    }

    if (screensaver) {
        return (
            <div className="screensaver">
                ScreenSaver
            </div>
        );
    }

    return (
        <div className="dashwall">
            <div className="menu">
                <Button color="black">
                    <Home fontSize="large" />
                </Button>
            </div>

            <div className="dash-container">
                <div className="card welcome-card">
                    <h2>Olá, Rafael Dantas!</h2>
                    <p className="next-event">Seu proximo evento é reunião importante daqui 2 horas.</p>
                    <div className="temperature">
                        <h3>{temperature}° C</h3>
                        <h4>{getWeatherStateLabel(weatherState)}</h4>
                    </div>
                </div>

                {spotifyState ? (
                    <div className="card spotify-card" style={{backgroundImage: `url(${HASS_URL}${spotifyState.attributes.entity_picture})`}}>

                        {spotifyState.state == 'idle' ? (
                            <>
                                <h1>Não está executando nada por enquanto.</h1>
                                <Button full={true} color="black" className="inverted" onClick={() => selectMedia()}>Começar a reproduzir</Button>
                            </>
                        ) : (
                            <>
                                {spotifyState.attributes.media_playlist ? (
                                    <small>{spotifyState.attributes.media_playlist}</small>
                                ) : null}
        
                                <h3>{spotifyState.attributes.media_title}</h3>
                                <p>{spotifyState.attributes.media_artist}</p>
        
                                <div className="media-control">
                                    <p onClick={() => mediaSpotifyService('media_previous_track')}><SkipPrevious fontSize='large'   /></p>
                                    {spotifyState.state === 'paused' ? (
                                        <p onClick={() => mediaSpotifyService('media_play')}><PlayArrow fontSize='large' /></p>
                                    ) : null}
        
                                    {spotifyState.state === 'playing' ? (
                                        <p onClick={() => mediaSpotifyService('media_pause')}><Pause fontSize='large'  /></p>
                                    ) : null}
        
                                    <p onClick={() => mediaSpotifyService('media_next_track')}><SkipNext fontSize='large'  /></p>

                                    <p onClick={() => selectMedia()}><PermMedia fontSize='large' /></p>
                                </div>
        
                                <p onClick={() => changeDevice()}>Reproduzindo em {spotifyState.attributes.source}</p>
                                
                            </>
                        )}                        
                    </div>
                ) : null}
            </div>
        </div>
    );
};