import firebase from 'firebase';
import * as React from 'react';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import _ from 'underscore';
import Button from '../../../uiKit/Button/Button';
import ListMenu from '../../../uiKit/ListMenu/ListMenu';
import Tooltip from '../../../uiKit/tooltip/Tooltip';
import { Type } from '../models';
import './list.scss';
import { Stage, WishListItem, WishListType } from './models';

export default () => {
    const history = useHistory();
    const [selectedType, setSelectedType] = React.useState<WishListType|null>(WishListType.BOOK);
    const [tooltip, setTooltip] = React.useState<string|null>(null);
    const [items, setItems] = React.useState<WishListItem[]>([]);

    const typeList = [
        //{ id: 'all', title: 'Todos' },
        { id: WishListType.BOOK, title: 'Livros' },
        { id: WishListType.MOVIE, title: 'Filmes' },
        { id: WishListType.TVSHOW, title: 'Séries' },
        //{ id: 'article', title: 'Artigos' },
    ];

    const getStageLabels = (type: WishListType) => {
        switch(type) {
            case WishListType.BOOK:
                return {
                    [Stage.SEEING]: 'Lendo',
                    [Stage.TO_SEE]: 'Para ler',
                    [Stage.SEEN]: 'Lido',
                    [Stage.ABANDONED]: 'Abandonado'
                };

            case WishListType.MOVIE || WishListType.TVSHOW:
                return {
                    [Stage.SEEING]: 'Assistindo',
                    [Stage.TO_SEE]: 'Para ver',
                    [Stage.SEEN]: 'Assistido',
                    [Stage.ABANDONED]: 'Abandonado'
                }

            default: 
                return {
                    [Stage.SEEING]: 'Vendo',
                    [Stage.TO_SEE]: 'Para ver',
                    [Stage.SEEN]: 'Visto',
                    [Stage.ABANDONED]: 'Abandonado'
                }
        }
    }

    const getStageLabel = (type: WishListType, stage: Stage) => {
        return getStageLabels(type)[stage];
    }

    const getDefaultPlataforms = (type: WishListType) => {
        switch(type) {
            case WishListType.BOOK:
                return {
                    'Kindle': 'Kindle',
                    'Físico': 'Físico',
                    'Download': 'Download'
                };

            case WishListType.MOVIE:
            case WishListType.TVSHOW:
                return {
                    'Netflix': 'Netflix',
                    'Prime Video': 'Prime Video',
                    'Disney+': 'Disney+',
                    'GloboPlay': 'GloboPlay',
                    'Telecine': 'Telecine',
                    'HBO': 'HBO',
                    'Paramount+': 'Paramount+' 
                };

            default:
                return {};
        }
    };

    type DirectoryItem = {
        id: string|null;
        title: string;
        image: string|null;
        type: WishListType
    }

    const getPlataformsByDirectoryItem = async(item: DirectoryItem) => {
        switch(item.type) {
            case WishListType.BOOK:
                return getDefaultPlataforms(item.type);

            case WishListType.MOVIE:
            case WishListType.TVSHOW:
                const responseMovie = await fetch(`https://api.themoviedb.org/3/${item.type == WishListType.MOVIE ? 'movie' : 'tv'}/${item.id}/watch/providers?api_key=8cdb408e27e077ae9f113c9a277f30a8`);
                if (responseMovie.status != 200) return getDefaultPlataforms(item.type);
                
                const payloadMovie = await responseMovie.json();
                if (!payloadMovie.results.BR) return {};

                const results = payloadMovie.results.BR;
                let platform: any = {};

                if (results.buy) {
                    results.buy.forEach((item: any) => {
                        platform[`[Comprar] ${item.provider_name}`] = `[Comprar] ${item.provider_name}`;
                    });
                }

                if (results.rent) {
                    results.rent.forEach((item: any) => {
                        platform[`[Alugar] ${item.provider_name}`] = `[Alugar] ${item.provider_name}`;
                    });
                }

                if (results.flatrate) {
                    results.flatrate.forEach((item: any) => {
                        platform[`[Streaming] ${item.provider_name}`] = `[Streaming] ${item.provider_name}`;
                    });
                }

                return platform;
                
        }
    };

    const getItensInDirectory = async(title: string): Promise<Array<DirectoryItem>|undefined> => {
        switch(selectedType) {
            case WishListType.BOOK:
                const responseBook = await fetch(`https://www.googleapis.com/books/v1/volumes?q=${encodeURI(title)}&key=AIzaSyDU2doe-R0bncOARJWs0nIv9-ArQRQeiwU&maxResults=30`);
                if (responseBook.status !== 200) return undefined;

                const payloadBook = await responseBook.json();
                return payloadBook.items.map((item: any) => ({
                    title: item.volumeInfo.title,
                    image: item.volumeInfo.imageLinks?.thumbnail || null,
                    type: selectedType
                }));

            case WishListType.MOVIE:
            case WishListType.TVSHOW:
                const responseMovie = await fetch(`https://api.themoviedb.org/3/search/${selectedType == WishListType.MOVIE ? 'movie' : 'tv'}?api_key=8cdb408e27e077ae9f113c9a277f30a8&query=${title}&language=pt-BR`);
                if (responseMovie.status !== 200) return undefined;

                const payloadMovie = await responseMovie.json();
                return payloadMovie.results.map((item: any) => ({
                    id: item.id,
                    title: item.title || item.name,
                    image: `https://www.themoviedb.org/t/p/w600_and_h900_bestv2${item.poster_path}`,
                    type: selectedType
                }));
            
        }
    };

    const createNew = async(titlePrefill: string = '') => {
        if (!selectedType) return;
        
        const createModal = Swal.mixin({
            confirmButtonText: 'Proximo &rarr;',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            progressSteps: ['1', '2', '3', '4']
        });
        
        const title = await createModal.fire({
            title: 'Título',
            input: 'text',
            inputValue: titlePrefill,
            currentProgressStep: '0'
        });

        if (title.isDismissed) return;

        const newItem: WishListItem = {
            title: title.value,
            platform: '',
            type: selectedType,
            stage: Stage.TO_SEE
        };

        let platforms: any = getDefaultPlataforms(selectedType);
        const itens = await getItensInDirectory(title.value);
        if (!itens) {
            const errorDirectory = await createModal.fire({
                title: 'Erro ao buscar título no diretorio.',
                icon: 'error',
                html: `Não foi possível pesquisar o título no diretorio.<br /><br />Deseja continuar com o título ${newItem.title}?`,
                confirmButtonText: `Continuar`,
                cancelButtonText: 'Tentar novamente',
                currentProgressStep: '0',
                allowOutsideClick: false
            });

            if (errorDirectory.isDismissed) {
                createNew(newItem.title);
                return;
            }
        } else if (itens.length < 1) {
            const titleNotFound = await createModal.fire({
                title: 'Título não encontrado.',
                icon: 'question',
                html: `O título informado não foi encontrado no diretorio.<br /><br />Deseja continuar com o título ${newItem.title}?`,
                confirmButtonText: `Continuar`,
                cancelButtonText: 'Digitar outro título',
                currentProgressStep: '0',
                allowOutsideClick: false
            });

            if (titleNotFound.isDismissed) {
                createNew(newItem.title);
                return;
            }
        } else {
            let resultItem = itens[0];
            let continueWithoutDirectory = false;
            if (itens.length > 1) {
                const options: any = {};
                itens.forEach((item, key: number) => {
                    options[key] = item.title;
                });


                const selectDirectoryItem = await createModal.fire({
                    title: 'Selecione',
                    input: 'select',
                    inputOptions: options,
                    confirmButtonText: 'Selecionar',
                    cancelButtonText: 'Inserir outro título',
                    denyButtonText: 'Continuar sem diretorio',
                    showDenyButton: true,
                    currentProgressStep: '0',
                    allowOutsideClick: false
                });

                if (selectDirectoryItem.isConfirmed) {
                    resultItem = itens[selectDirectoryItem.value];
                }

                if (selectDirectoryItem.isDismissed) {
                    createNew(newItem.title);
                    return;
                }

                if (selectDirectoryItem.isDismissed) {
                    continueWithoutDirectory = true;
                }

                if (!continueWithoutDirectory) {
                    const resultConfirmDirectory = await createModal.fire({
                        title: 'É este?',
                        html: `
                            <img src="${resultItem.image || require('./sem-imagem.png')}" height="300px" width="auto"> <br/>
                            <h3>${resultItem.title}</h3>
                        `,
                        confirmButtonText: 'Este mesmo!',
                        cancelButtonText: 'Inserir outro título',
                        denyButtonText: 'Continuar sem imagem',
                        showDenyButton: true,
                        currentProgressStep: '0',
                        allowOutsideClick: false
                    });

                    if (resultConfirmDirectory.isDismissed) {
                        createNew();
                        return;
                    }

                    if (resultConfirmDirectory.isConfirmed) {
                        newItem.title = resultItem.title;
                        if (resultItem.image) newItem.image = resultItem.image;
                        platforms = await getPlataformsByDirectoryItem(resultItem);
                    }
                }
            }
        }

        let platform = await createModal.fire({
            title: 'Plataforma',
            input: 'select',
            inputOptions: {
                ...platforms,
                'Outro': 'Outro'
            },
            currentProgressStep: '1',
            allowOutsideClick: false
        });

        if (platform.isDismissed) return;

        if (platform.value == 'Outro') {
            platform = await createModal.fire({
                title: 'Plataforma',
                input: 'text',
                currentProgressStep: '1',
                allowOutsideClick: false
            });

            if (platform.isDismissed) return;
        }

        newItem.platform = platform.value;

        let stage = await createModal.fire({
            title: 'Estado',
            input: 'select',
            inputOptions: getStageLabels(selectedType),
            currentProgressStep: '2',
            allowOutsideClick: false
        });

        if (stage.isDismissed) return;
        newItem.stage = stage.value;

        const confirmCreation = await createModal.fire({
            title: 'É este?',
            html: `
                <img src="${newItem.image || require('./sem-imagem.png')}" height="300px" width="auto"> <br/>
                <h3>${newItem.title}</h3>
                <p><strong>Plataforma:</strong> ${newItem.platform}</p>
                <p><strong>Estado:</strong> ${getStageLabel(newItem.type, newItem.stage)}</p>
            `,
            confirmButtonText: 'Confirmar!',
            cancelButtonText: 'Começar do começo',
            denyButtonText: 'Cancelar criação',
            showDenyButton: true,
            currentProgressStep: '3',
            allowOutsideClick: false
        });

        if (confirmCreation.isConfirmed) {
            setTooltip('Criando...');
            const newItemRef = firebase.firestore().collection('wishlist').doc()
            await newItemRef.set(newItem, {merge: true});
            setTooltip(null);
            setItems(_.sortBy([
                ...items,
                {
                    ...newItem,
                    id: newItemRef.id
                } as any
            ], 'stage'));
        }
    };

    const changeStage = async(item: WishListItem) => {
        const newStage = await Swal.fire({
            title: 'Mudar para',
            input: 'select',
            inputOptions: getStageLabels(item.type),
            inputValue: item.stage,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        });

        if (newStage.isConfirmed && newStage.value !== item.stage) {
            setTooltip('Alterando...');
            await firebase.firestore().collection('wishlist').doc(item.id).set({stage: newStage.value}, {merge: true});
            item.stage = newStage.value;
            setTooltip('Criado.');
            setTimeout(() => setTooltip(null), 2000);
        }
    };

    const fetchItems = async() => {
        if (!selectedType) return;
        setTooltip('Carregando...');
        const items = await firebase.firestore().collection('wishlist').where('type', '==', selectedType).orderBy('stage', 'asc').get();

        setItems(items.docs.map((item) => ({
            ...item.data(),
            id: item.id
        })) as any);

        setTooltip(null);
    };

    React.useEffect(() => {
        fetchItems();
    }, [selectedType]);

    return (
        <>
            {tooltip ? (
                <Tooltip>{tooltip}</Tooltip>
            ) : null}
            <Button color="black" className="back-dashboard" onClick={() => history.replace('/dashboard')}>Volta para dashboard</Button>
            <div style={{clear: 'both'}}></div>
            <ListMenu items={typeList} onSelect={(item) =>  setSelectedType(item.id as any)} selected={selectedType} />

            <div className="wish-list">
                <div className="create">
                    <Button color="green" onClick={createNew}>Criar Novo</Button>
                </div>

                {items.map((item, index) => (
                    <div className="item" key={index}>
                        <div className="image-header">
                            <img src={item.image ? item.image : require('./sem-imagem.png')} />
                            <div className="tag">{getStageLabel(item.type, item.stage)}</div>
                        </div>
                        <hr />
                        <h3>{item.title}</h3>
                        <div className="atributes">
                            <p><strong>Plataforma:</strong> {item.platform}</p>
                        </div>
                        <Button color="green" full={true} onClick={() => changeStage(item)}>Mudar Estado</Button>
                    </div>
                ))}
            </div>
        </>
    );
};