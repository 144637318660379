export enum WishListType {
    BOOK = 'book',
    MOVIE = 'movie',
    TVSHOW = 'tvshow'
}

export enum Stage {
    SEEING = 0,
    TO_SEE = 20,
    SEEN = 40,
    ABANDONED = 99
};

export type WishListItem = {
    id?: string,
    title: string,
    type: WishListType,
    image?: string,
    stage: Stage,
    platform: string
};