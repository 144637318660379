import Server from "./Server";
import Module from "../module";
import { ProfileType } from "../../models/profile.model";

export default {
    id: 'server',
    image: require('./../../assets/server-icon.png'),
    title: "Servidor",
    profile: ProfileType.personal,
    render: Server
} as Module;