import * as React from 'react';
import usePersistentState from '../../hooks/usePersistentState';
import { Profile, ProfileType } from '../../models/profile.model';
import './Welcome.scss';
import { useHistory } from 'react-router-dom';

export default () => {
    const [profile, setProfile] = usePersistentState<ProfileType|null>('profile', null);
    const history = useHistory();

    React.useEffect(() => {
        if (profile) {
            history.push('/dashboard');
        }
    }, [profile, history]);
    
    return (
        <div className="welcome-container">
            <h2>Selecione um perfil para continuar</h2>

            <div className="profiles">
                <div className="profile" onClick={() => setProfile(ProfileType.personal)}>
                    <img src={require('../../assets/personal-icon.png')} alt='Pessoal' />
                    <h2>Pessoal</h2>
                </div>

                <div className="profile" onClick={() => setProfile(ProfileType.work)}>
                    <img src={require('../../assets/professional-icon.png')} alt='professional' />
                    <h2>Profissional</h2>
                </div>
            </div>
        </div>
    );
};