import * as React from "react";

export default <T>(key: string, defaultValue: T): [T, React.Dispatch<T>] => {
    const [state, setState] = React.useState(
        () => JSON.parse((localStorage.getItem(`_state_${key}`) as any)) || defaultValue
    );

    React.useEffect(() => {
        localStorage.setItem(`_state_${key}`, JSON.stringify(state));
    }, [key, state]);

    return [state, setState];
};