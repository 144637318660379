import { faClock, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../components/shared/Header';
import Button from '../../uiKit/Button/Button';
import Tooltip from '../../uiKit/tooltip/Tooltip';
import { AutoCreate, AutoCreateDayOfWeek, AutoCreatePeriod, Project, ProjectType, Routine, Task } from './models';
import swal from '@sweetalert/with-react';
import DatePicker from "react-datepicker";
import './todo.scss';
import moment from 'moment';
import _ from 'underscore';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { createToast, createToastInfinite } from '../../uiKit/toast';
import { MODULE_ROUTE_PREFIX } from '../module';

export default () => {
    const { projectId, routineId } = useParams<{projectId: string, routineId?: string}>();
    const [project, setProject] = React.useState<Project>();
    const [isLoading, setIsLoading] = React.useState<string|null>('Carregando...');
    const [newTask, setNewTask] = React.useState<string>('');
    const [editingTask, setEditingTask] = React.useState<string|null>(null);
    const history = useHistory();

    React.useEffect(() => {
        fetchProject();
    }, [projectId, routineId]);

    const fetchProject = async() => {
        setIsLoading('Carrengando...');
        if (project) setProject((state) => {
            if (!state) return state;
            state.tasks = [];
            return state;
        })
        const projectSnapshot = firebase.firestore().collection('todo').doc(projectId);
        const projectData = await projectSnapshot.get();

        if (!projectData.exists) {
            setIsLoading(null);
            return;
        }

        const projectDataFetched = projectData.data() as Project;

        let routines = undefined;
        let template = undefined;
        let tasks = [];
        if (!routineId) {
            tasks = (await projectSnapshot.collection('tasks').get()).docs;

            if (projectDataFetched.type == ProjectType.ROUTINE || projectDataFetched.type == ProjectType.CHECKLIST) {
                routines = (await projectSnapshot.collection('routines').get()).docs.map((r) => ({
                    ...r.data() as Routine,
                    id: r.id
                }));
                template = (await projectSnapshot.collection('template').get()).docs.map((t) => ({
                    ...t.data() as Task,
                    id: t.id
                }));
            }
        } else {
            const routineRef = projectSnapshot.collection('routines').doc(routineId);
            const routine = await routineRef.get();
            tasks = (await routineRef.collection('tasks').get()).docs;
            projectDataFetched.title = `${projectDataFetched.title}: ${routine.data()?.title}`
        }

        setProject(({
            ...projectDataFetched,
            tasks: tasks.sort((a, b) => b.data().created_at - a.data().created_at).sort((a, b) => a.data().done - b.data().done).map((t) => ({
                ...t.data() as Task,
                id: t.id
            })) as Task[],
            routines,
            template
        } as Project));
        setIsLoading(null);
    };

    const addTask = async() => {
        if (!newTask) return;

        const ref = firebase.firestore().collection('todo').doc(projectId).collection('tasks').doc();

        const newTaskElement = {
            content: newTask,
            done: false,
            created_at: firebase.firestore.Timestamp.fromDate(new Date())
        };

        setIsLoading("Salvando...");

        project?.tasks.unshift(newTaskElement);

        await ref.set(newTaskElement);

        createToast('Salvo', 'success', 1000);
        setNewTask('');
    };

    const toggleTask = async(task: Task, routine: string) => {
        setIsLoading("Salvando...");
        const newStatus = !task.done;

        setProject((state) => {
            if (!state) return;

            const indexTask = _.indexOf(state.tasks, task);
            state.tasks[indexTask].done = newStatus;
            return state;
        });

        if (!routine) {
            await firebase.firestore().collection('todo').doc(projectId).collection('tasks').doc(task.id).set({
                done: newStatus,
                doned_at: new Date()
            }, {merge: true});
            setIsLoading(null);
            return;
        }

        await firebase
            .firestore()
            .collection('todo')
            .doc(projectId)
            .collection('routines')
            .doc(routineId)
            .collection('tasks')
            .doc(task.id)
            .set({
                done: newStatus,
                doned_at: firebase.firestore.Timestamp.fromDate(new Date())
            }, {merge: true});
        setIsLoading(null);
    };

    const modifyTemplate = async() => {
        const modifyModal = withReactContent(Swal);

        const addToTemplate = async() => {
            const titleBox = await Swal.fire({
                input: 'text',
                text: 'Titíulo'
            });

            
            if (!titleBox.isConfirmed || !titleBox.value) {
                modifyTemplate();
                return;
            };

            const ref = firebase
                .firestore()
                .collection('todo')
                .doc(projectId)
                .collection('template')
                .doc();

            const newTask: Task = {
                id: ref.id,
                content: titleBox.value,
                done: false
            }

            project?.template?.push(newTask);
            modifyTemplate();

            setIsLoading('Salvando...');
            await ref.set(newTask);
            setIsLoading('');
        };
        
        await modifyModal.fire({
            title: "Modificar Template",
            width: '100%',
            customClass: {
                container: 'modify-popup'
            },
            heightAuto: false,
            html: (
                <>
                    <div className="task">
                        <Button color="green" className="add full" onClick={async() => {
                            modifyModal.close()
                            addToTemplate()
                        }}>Adicionar</Button>
                         
                        <div style={{textAlign: 'left'}}>
                            {project?.template?.map((task, index) => (
                                <div style={{display: 'flex', placeItems: 'center'}} key={index}>
                                    <input type="checkbox" disabled checked style={{transform: "scale(1.5)", marginRight: 10}}/>
                                    <h3>{task.content}</h3>
                                </div>
                            ))}  
                        </div>                      
                    </div>
                </>
            ),
            showCloseButton: true,
            showConfirmButton: false 
        });
    };

    const autoCreate = () => {
        const autoCreateModal = withReactContent(Swal);
        const dayOfWeekText = {
            [AutoCreateDayOfWeek.MONDAY]: 'segunda-feira',
            [AutoCreateDayOfWeek.TUESDAY]: 'terça-feira',
            [AutoCreateDayOfWeek.WEDNESDAY]: 'quarta-feira',
            [AutoCreateDayOfWeek.THURSDAY]: 'quinta-feira',
            [AutoCreateDayOfWeek.FRIDAY]: 'sexta-feira',
            [AutoCreateDayOfWeek.SATURDAY]: 'sabado',
            [AutoCreateDayOfWeek.SUNDAY]: 'domingo'
        }

        const configureAutoCreate = async() => {
            autoCreateModal.close();

            const configureModal = Swal.mixin({
                title: 'Criação Automatizada',
                progressSteps: ['1', '2', '3'],
                confirmButtonText: 'Proximo &rarr;'
            });

            const period = await configureModal.fire({
                title: 'Périodo',
                currentProgressStep: '0',
                progressSteps: ['1', '2'],
                input: 'select',
                inputOptions: {
                    [AutoCreatePeriod.DAILY]: 'Diaramente',
                    [AutoCreatePeriod.WEEKLY]: 'Semanalmente',
                    [AutoCreatePeriod.MONTHLY]: 'Mensalmente',
                }
            });

            if(period.isDismissed) {
                autoCreate();
                return;
            };

            const newAutoCreate: AutoCreate = {
                period: period.value,
                hour: 12
            }

            if (period.value == AutoCreatePeriod.WEEKLY) {
                const week = await configureModal.fire({
                    title: 'Dia da semana',
                    currentProgressStep: '1',
                    input: 'select',
                    inputOptions: dayOfWeekText
                });

                if (week.isDismissed) {
                    autoCreate();
                    return;
                }

                newAutoCreate.dayOfWeek = week.value;
            }

            if (period.value == AutoCreatePeriod.MONTHLY) {
                const day = await configureModal.fire({
                    title: 'Dia do mês',
                    currentProgressStep: '1',
                    input: 'range',
                    inputAttributes: {
                        min: 1,
                        max: 31,
                        setp: 1
                    } as any,
                    inputValue: '5',
                });

                if (day.isDismissed) {
                    autoCreate();
                    return;
                }

                newAutoCreate.day = day.value;
            }

            const hour = await configureModal.fire({
                title: 'Horário',
                progressSteps: period.value == AutoCreatePeriod.DAILY ? ['1', '2'] : ['1', '2', '3'],
                currentProgressStep: period.value == AutoCreatePeriod.DAILY ? '1' : '2',
                input: 'range',
                inputAttributes: {
                    min: 1,
                    max: 23,
                    setp: 1
                } as any,
                inputValue: '12',
                confirmButtonText: 'Finalizar'
            });

            if (hour.isDismissed) {
                autoCreate();
                return;
            }

            newAutoCreate.hour = hour.value;

            if (project) project.autocreate = newAutoCreate;
            autoCreate();
            firebase.firestore().collection('todo').doc(projectId).set({
                autocreate: newAutoCreate
            }, {merge: true});
        };

        const disableAutoCreate = async() => {
            if (project) project.autocreate = undefined;
            autoCreate();
            firebase.firestore().collection('todo').doc(projectId).set({
                autocreate: firebase.firestore.FieldValue.delete()
            }, {merge: true});
        };

        const getDayOfWeekText = () => {
            if (!project?.autocreate?.dayOfWeek) return;
            return dayOfWeekText[project.autocreate.dayOfWeek];
        }

        autoCreateModal.fire({
            title: 'Criação automatizada',
            html: (
                <>
                    {project?.autocreate ? (
                        <>
                            <p>Configurado para {{
                                [AutoCreatePeriod.DAILY]: `diaramente às ${project.autocreate.hour} horas.`,
                                [AutoCreatePeriod.WEEKLY]: `semanalmente, ${getDayOfWeekText()} às ${project.autocreate.hour} horas.`,
                                [AutoCreatePeriod.MONTHLY]: `mensalmente, todo dia ${project.autocreate.day} às ${project.autocreate.hour} horas.`,
                            }[project.autocreate.period]}</p>
                            <Button color="blue" full={true} onClick={() => configureAutoCreate()}>Alterar</Button>
                            <Button color="red" full={true} style={{marginTop: 10}} onClick={() => disableAutoCreate()}>Desativar</Button>
                        </>
                    ) : (
                        <>
                            <p>Criação automatizada não está configudado.</p>
                            <Button color="blue" full={true} onClick={() => configureAutoCreate()}>Configurar</Button>
                        </>
                    )}
                </>
            ),
            showCloseButton: true,
            showConfirmButton: false 
        })
    };

    const createRoutine = async() => {
        const title = await Swal.fire({
            title: 'Nome do linha',
            input: 'text',
            confirmButtonText: 'Confirmar'
        });

        if (!title.isConfirmed || !title.value) {
            return;
        }

        const newRoutine = {
            title: title.value,
            created_at: firebase.firestore.Timestamp.fromDate(new Date())
        }

        createToastInfinite('Criando...', 'info');
        const ref = firebase.firestore().collection('todo').doc(projectId).collection('routines').doc();
        await ref.set(newRoutine);

        const batch = firebase.firestore().batch();
        project?.template?.forEach((item) => {
            delete item.id;
            batch.set(ref.collection('tasks').doc(), item);
        });

        await batch.commit();

        window.location.reload();
    };

    const editTask = async(taskId: string, newContent: string) => {
        setIsLoading("Carregando...");
        await firebase.firestore().collection('todo').doc(projectId).collection('tasks').doc(taskId).set({
            content: newContent
        }, {merge: true});
        await fetchProject();
    };

    const removeTodo = async(task: Task) => {
        const confirmBox = await Swal.fire({
            title: 'Você tem certeza?',
            text: 'Certeza que deseja remover este todo? Não será mais possível recuperar o mesmo.',
            icon: 'question',
            denyButtonText: 'Remover!',
            showDenyButton: true,
            confirmButtonText: 'Não remover',
        });

        if (!confirmBox.isDenied) return;
        setIsLoading("Salvado...");
        
        if (project?.tasks) project.tasks = _.without(project.tasks, task);
        await firebase.firestore().collection('todo').doc(projectId).collection('tasks').doc(task.id).delete();
        setIsLoading(null);
    };

    const removeProject = async() => {
        const remove = window.confirm('Realmente deseja remover esse projeto?');

        if (remove) {
            setIsLoading("Carregando...");
            if (!routineId) {
                await firebase.firestore().collection('todo').doc(projectId).delete();
                history.goBack();
                return;
            }

            await firebase.firestore().collection('todo').doc(projectId).collection('routines').doc(routineId).delete();
            history.goBack();
        }
    };

    const openDateSelection = async(todoId: string|undefined, index: number) => {
        if (!todoId || !project) return;
        let date: moment.Moment = moment();
        
        const defineDate = await swal(
            <>
                <input type="date" defaultValue={`${date.format('YYYY-MM-DD')}`} onChange={(event) => date = moment(event.currentTarget.value)} />
            </>,
            {
                buttons: {
                    cancel: {
                        text: "Cancelar",
                        value: false
                    },
                    define: {
                        text: "Definir data",
                        value: true
                    }
                }
            }
        );
        
        if (date && defineDate) {

            if (!date.isSame(moment(), 'day')) {
                date = date.add('6', 'hours');
            }
        
            setIsLoading("Carregando...");
            await firebase.firestore().collection('todo').doc(projectId).collection('tasks').doc(todoId).set({
                expire_in: date.toDate()
            }, {merge: true});

            project.tasks[index].expire_in = firebase.firestore.Timestamp.fromDate(date.toDate());

            setIsLoading(null);
            return;
        }

        swal('', 'Você não selecionou uma data corretamente.', 'danger')
    };

    if (isLoading == null && !project) {
        return (
            <>
                <Header title="Dantascenter" />
                <p>Projeto não encontrado.</p>
            </>
        )
    }

    const TaskEl = ({task, index, routine, template}: any) => {
        return (
            <div className={`task ${task.done ? 'done' : null}`}>
                <div className="task-content" onClick={() => {
                    if (editingTask != task.id) {
                        if (!template) toggleTask(task, routine);
                    }
                }}>
                    {!template ? (
                        <input type="checkbox" checked={task.done} />
                    ) : (
                        <input type="checkbox" disabled={true} />
                    )}
                    <h3 contentEditable={editingTask !== null} className={`task-${task.id}`} onBlur={() => {
                        editTask(task.id || '', ((document.querySelector(`.task-${task.id}`)) as any).innerText)
                        setEditingTask(null);
                    }} onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            editTask(task.id || '', ((document.querySelector(`.task-${task.id}`)) as any).innerText)
                            setEditingTask(null);   
                        }
                    }}>{task.content}</h3>
                    
                </div>

                {routine ? null : (
                    <div className="task-action">
                        {task.expire_in ? (
                            <small className={moment(task.expire_in.seconds * 1000).isBefore() ? 'date expired' : 'date'} onClick={() => openDateSelection(task.id, index)}>
                                {moment(task.expire_in.seconds * 1000).calendar()}                        
                            </small>
                        ) : (
                            <FontAwesomeIcon icon={faClock} className="clock" onClick={() => openDateSelection(task.id, index)} />
                        )}
                            <FontAwesomeIcon icon={faPen} className="edit" onClick={() => {
                                setEditingTask(task.id || null);
                                setTimeout(() => {
                                    ((document.querySelector(`.task-${task.id}`)) as any).focus()
                                    document.execCommand('selectAll',false);
                                }, 500);
                            }}/>
                            <FontAwesomeIcon icon={faTrash} className="remove" onClick={() => removeTodo(task)} />
                    </div> 
                )}
            </div>
        );
    }

    return (
        <div className="container">
            <div className="back">
                <Button color="black" onClick={() => history.goBack()}>Voltar</Button>
                {(project?.type == ProjectType.ROUTINE || project?.type == ProjectType.CHECKLIST) && !routineId ? (
                    <>
                        <Button color="green" className="modify" onClick={() => autoCreate()}>Criação Automatizada</Button>
                        <Button color="blue" className="modify" onClick={() => modifyTemplate()}>Modificar template</Button>
                    </>
                ) : null}
                <Button color="red" className="remove" onClick={() => removeProject()}>Remover</Button>
            </div>

            <h2>{project?.title || ''}</h2>
            
            {project?.type == ProjectType.PROJECT ? (
                <div className="task">
                    <input className="new-task" type="text" placeholder="Nova tarefa..." value={newTask} onChange={(event) => setNewTask(event.currentTarget.value)} onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            addTask();
                        }
                    }} />
                    {newTask ? (
                        <Button color="green" className="add" onClick={() => addTask()}>Adicionar</Button>
                    ) : null}
                    
                </div>
            ) : null}

            {!routineId ? (
                <>
                    {_.sortBy(project?.tasks as any, 'done').map((task, index) => (
                        <TaskEl task={task} index={index} routine={false} />
                    ))}
                </>
            ) : null}
            
            

            {(project?.type == ProjectType.ROUTINE || project?.type == ProjectType.CHECKLIST) && !routineId ? (
                <>
                    <div className="project new" onClick={() => createRoutine()}>
                        <div className="header">
                            <div className="title">
                                <h2>Novo item</h2>
                                <small>Criar uma nova linha na rotina.</small>
                            </div>
                        </div>
                    </div>

                    {_.sortBy(project.routines as any, 'created_at').reverse().map((routine, index) => (
                        <div className="project routine" key={index} onClick={() => history.push(`${MODULE_ROUTE_PREFIX.replace(':moduleId', 'todo')}/${projectId}/${routine.id}`)}>
                            <div className="header">
                                <div className="title">
                                    <h2>{routine.title}</h2>
                                    <small>{moment(routine.created_at.seconds * 1000).calendar()}</small>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : null}

            {routineId ? (
                <>
                    {_.sortBy(project?.tasks as any, 'done').map((task, index) => (
                        <TaskEl task={task} index={index} routine={true} />
                    ))}
                </>
            ) : null}

            <Tooltip content={isLoading} />
        </div>
    )
};