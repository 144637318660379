import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Note } from './models';
import Header from '../../components/shared/Header';
import './Notes.scss';
import { Moment } from 'moment';
import { UserContext, DarkModeContext } from '../../App';
import moment from 'moment';
import deepEqual from 'deep-equal';
import Editor from 'draft-js-plugins-editor';
import { EditorState, convertFromRaw, convertToRaw, RichUtils, DraftHandleValue } from 'draft-js';
import Prism from 'prismjs';
import createDraftMarkdownPlugin from 'draft-js-markdown-shortcuts-plugin';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createHashtagPlugin from 'draft-js-hashtag-plugin';
import createPrismPlugin from 'draft-js-prism-plugin';
import 'draft-js-hashtag-plugin/lib/plugin.css';
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-scala';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-kotlin';
import 'prismjs/components/prism-perl';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-swift';
import "prismjs/themes/prism.css";

import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    CodeBlockButton,
} from 'draft-js-buttons';

enum NoteStatus {
    LOADING,
    UNTOUCHABLE,
    NOT_FOUND,
    SAVING,
    SAVED
};

const inlineToolbarPlugin = createInlineToolbarPlugin();
const toolbarPlugin = createToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;
const { Toolbar } = toolbarPlugin;
const prismPlugin = createPrismPlugin({
    prism: Prism
});
const draftJsPlugins = [
    createHashtagPlugin(),
    createDraftMarkdownPlugin(),
    inlineToolbarPlugin,
    toolbarPlugin,
    prismPlugin
];
  
export default () => {
    const { noteId } = useParams<{noteId: string}>();
    const [noteStatus, setNoteStatus] = React.useState<NoteStatus>(NoteStatus.LOADING)
    const [note, setNote] = React.useState<Note|null>(null);
    const [lastSave, setLastSave] = React.useState<{title: string, content: any, modified_at: Moment}|null>(null);
    const titleRef = React.useRef<any>();
    const contentRef = React.useRef<any>();
    const user = React.useContext(UserContext);
    const darkMode = React.useContext(DarkModeContext);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const history = useHistory();

    React.useEffect(() => {
        let mounted = true;
        let noteSnapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;

        const fetchNote = async() => {
            noteSnapshot = await firebase
                .firestore()
                .collection('notes')
                .doc(noteId)
                .get();

            if (mounted) {
                setFetchedDataToState();
            }
        }

        const setFetchedDataToState = () => {
            if (!noteSnapshot.exists) {
                return setNoteStatus(NoteStatus.NOT_FOUND);
            }

            const noteData = noteSnapshot.data();

            setNoteStatus(NoteStatus.UNTOUCHABLE);
            setNote(({
                id: noteSnapshot.id,
                ...noteData
            } as Note));

            if (noteData && noteData.content && mounted) {
                setEditorState(EditorState.createWithContent(convertFromRaw(noteData.content)));
            }
        };

        fetchNote();
        return () => { mounted = false };
    }, [noteId]);

    React.useEffect(() => {
        const autoSave = setInterval(async() => {
            if (!note) return;
            if (!titleRef.current) return;
            if (noteStatus === NoteStatus.SAVING) return;
            const contentToSave = convertToRaw(editorState.getCurrentContent());
            if (deepEqual(lastSave?.content, contentToSave) && lastSave?.title === titleRef.current.innerHTML) return;

            const modified_at = new Date();
            const titleToSave = titleRef.current.innerHTML;

            setNoteStatus(NoteStatus.SAVING);

            await firebase
                .firestore()
                .collection('notes')
                .doc(note.id)
                .set({
                    title: titleRef.current.innerHTML,
                    content: contentToSave,
                    modified_at
                }, {merge: true})

            setLastSave({
                title: titleToSave,
                content: contentToSave,
                modified_at: moment(modified_at)
            });
            
            setNoteStatus(NoteStatus.SAVED);    
            
        }, 2000);

        window.onbeforeunload = () => {
            if (noteStatus === NoteStatus.SAVING) {
                return true;
            }

            return (deepEqual(lastSave?.content, convertToRaw(editorState.getCurrentContent())) && lastSave?.title === titleRef.current.innerHTML) ? null : true;
        };

        return () => { 
            clearInterval(autoSave);
        };
    }, [titleRef, contentRef, note, noteStatus, user, lastSave, editorState]);

    if (noteStatus === NoteStatus.LOADING) {
        return (
            <p>Carregando...</p>
        )
    }

    if (noteStatus === NoteStatus.NOT_FOUND || !note) {
        return (
            <>
                <Header title="DantasCenter" />
                <p>Nota não encontrada.</p>
            </>
        )
    }

    const getSaveInfo = () => {
        if (noteStatus === NoteStatus.SAVING) {
            return 'Salvando...';
        }

        if (noteStatus === NoteStatus.SAVED) {
            return `Salvo às ${lastSave?.modified_at.format('LTS')}`
        }

        return '';
    }

    const removeNote = async() => {
        const wantsRemove = window.confirm("Tem certeza que deseja remover? Essa ação não é reversivel.");
        if (wantsRemove) {
            setNoteStatus(NoteStatus.LOADING);
            await firebase
                .firestore()
                .collection('notes')
                .doc(note.id)
                .delete();
            history.goBack();
        }
    }

    const handleKeyCommand = (command: string, editorState: EditorState): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }

        return 'not-handled';
    };
    return (
        <div className={`notes-module note-view ${darkMode ? 'dark' : null}`}>
            <small dangerouslySetInnerHTML={{__html: getSaveInfo()}} />
            <div className="top">
                <h1 ref={titleRef} placeholder="Título" className="title" contentEditable={true} dangerouslySetInnerHTML={{__html: note.title}}></h1>
                <button onClick={() => window.location.href = '/module/notes'} className="button black">Voltar</button>
                &emsp;
                <button onClick={removeNote} className="button red">Remover</button>
            </div>
            

            <div className="content">
                <Editor
                    editorState={editorState}
                    onChange={(newEditorState:any) => setEditorState(newEditorState)}
                    handleKeyCommand={handleKeyCommand}
                    plugins={draftJsPlugins}
                    ref={contentRef}
                />

                <Toolbar />

                <InlineToolbar>
                    {
                        (externalProps: any) => (
                            <div>
                                <ItalicButton {...externalProps} />
                                <BoldButton {...externalProps} />
                                <UnderlineButton {...externalProps} />
                                <CodeBlockButton {...externalProps} />
                            </div>
                        )
                    }
                </InlineToolbar>

                

                {/*<ContentEditableWithTab>
                    <div id="noteContent" ref={contentRef} contentEditable={true} dangerouslySetInnerHTML={{__html: note.content}} placeholder="Conteúdo"></div>
                </ContentEditableWithTab>*/}
            </div>
        </div>
    );
};