import React from 'react';
import './card-details.scss';

export default ({children, color, size, ...props}: any) => {
    return (
        <div className={`uikit card-details ${color} ${size}`} {...props}>
            {children}
        </div>

    );
}