import Swal, { SweetAlertIcon } from "sweetalert2";

export const createToast = (content: string, icon?: SweetAlertIcon, timer?: number) => {
    return Swal.fire({
        toast: true,
        position: 'top-left',
        showConfirmButton: false,
        timer: timer,
        timerProgressBar: timer != undefined,
        title: content,
        icon,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
};

export const createToastInfinite = (content: string, icon?: SweetAlertIcon) => {
    return Swal.fire({
        toast: true,
        position: 'top-left',
        showConfirmButton: false,
        timer: 999999999,
        timerProgressBar: false,
        title: content,
        icon,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
};