import * as React from 'react';
import { useParams } from 'react-router-dom';
import modules from '.';
import Header from '../components/shared/Header';

export default () => {
    const { moduleId } = useParams();
    const moduleToRender = modules.find((module) => {
        return module.id === moduleId
    });

    if (!moduleToRender) {
        return (
            <>
                <Header title="DantasCenter" />
                <p>Modulo não encontrado.</p>
            </>
        );
    }

    return (
        <>
            <moduleToRender.render />
        </>
    );
};