import { ProfileType } from "../../models/profile.model";
import Module from "../module";
import Finance from "./Finance";

export default {
    id: 'finance',
    image: require('./../../assets/checklist-icon.png'),
    title: "Finanças",
    profile: ProfileType.personal,
    render: Finance
} as Module;