import serverModule from "./server/server.module";
import notesModule from "./notes/notes.module";
import todoModule from "./todo/todo.module";
import checklistModule from "./checklist/checklist.module";
import financeModule from "./finance/finance.module";
import shoppingListModule from "./shopping-list/shopping-list.module";
import marketModule from "./market/market.module";
import wishList from "./wish-list/wish-list";
import dashwallModule from "./dashwall/dashwall.module";

export default [
    notesModule,
    //serverModule,
    todoModule,
    //checklistModule,
    wishList,
    financeModule,
    shoppingListModule,
    marketModule,
    dashwallModule
]