import * as React from 'react';
import { useHistory } from 'react-router-dom';
import LockPin from '../../shared/lock-pin/LockPin';
import settings from '../../shared/settings';
import Button from '../../uiKit/Button/Button';
import { MODULE_ROUTE_PREFIX } from '../module';

export default () => {
    const history = useHistory();
    const [locked, setLocked] = React.useState(true);

    if (locked) {
        return (
            <>
                <LockPin onUnlock={() => setLocked(false)} />
                <div style={{textAlign: 'center', marginTop: 15}}>
                    <Button color="black" onClick={() => history.replace('/dashboard')}>Voltar para Dashboard</Button>
                </div>
            </>
        );
    }

    return (
        <>

        <Button color="black" style={{margin: 15, right: 0}} onClick={() => history.replace('/dashboard')}>Voltar para Dashboard</Button>

        <div className="financePage">
            <div className="header"></div>
            <div className="container">
                <div className="menus">
                    <div className="item" onClick={() => history.push(`${MODULE_ROUTE_PREFIX.replace(':moduleId', 'finance')}/earnings`)}>
                        <img src={require('./assets/earninngs.png')} />
                        <h3>Ganhos</h3>
                    </div>
                    <div className="item">
                        <img src={require('./assets/patrimony.png')} />
                        <h3>Patrimonio</h3>
                    </div>
                    <div className="item" onClick={() => history.push(`${MODULE_ROUTE_PREFIX.replace(':moduleId', 'finance')}/budgets`)}>
                        <img src={require('./assets/budget.png')} />
                        <h3>Orçamentos</h3>
                    </div>
                    <div className="item">
                        <img src={require('./assets/planning.png')} />
                        <h3>Planejamento</h3>
                    </div>
                    <div className="item" onClick={() => history.push(`${MODULE_ROUTE_PREFIX.replace(':moduleId', 'finance')}/expenses`)}>
                        <img src={require('./assets/expense.png')} />
                        <h3>Despesas</h3>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};