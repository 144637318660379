import * as React from 'react';
import usePersistentState from '../../hooks/usePersistentState';
import { useHistory } from 'react-router-dom';
import './Header.scss';
import { ProfileType } from '../../models/profile.model';

type Props = {
    title: string;
};

export default ({title}: Props) => {
    const [profile, setProfile] = usePersistentState<null|ProfileType>('profile', null);
    const history = useHistory();
 
    React.useEffect(() => {
        if (!profile) {
            history.replace('/');
        }
    }, [profile, history]);

    return (
        <div className="header-shared">
            <header>
                <h2 className="logo">
                    {title}
                    { history.location.pathname !== '/dashboard' ? (
                        <button className="button small" onClick={() => history.goBack()}>Voltar</button>
                    ) : null}
                </h2>
                <div className="profile">
                    <h2 className="profile">{
                        ({
                            [ProfileType.personal]: "Pessoal",
                            [ProfileType.work]: "Profissional",
                            'unknown': 'Desconhecido'
                        } as any)[profile || 'unknown']
                    } <button className="button small" onClick={() => setProfile(null)}>Trocar</button></h2>
                </div>
            </header>
        </div>
    );
};