import { ProfileType } from "../../models/profile.model";
import Module from "../module";
import List from "./List/List";

export default {
    id: 'wishlist',
    image: require('./../../assets/wish-list-icon.png'),
    profile: ProfileType.personal,
    title: 'Lista Ler/Assistir',
    render: List
} as Module;