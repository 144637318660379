import * as React from 'react';
import './Dashboard.scss';
import Header from '../shared/Header';
import modules from '../../modules';
import usePersistentState from '../../hooks/usePersistentState';
import { useHistory } from 'react-router-dom';

export default () => {
    const [profile] = usePersistentState<string|null>('profile', null);
    const history = useHistory();

    return (
        <div className="dashboard-container">
            <Header title="DantasCenter" />

            <div className="modules">
                {modules.map((module) => {
                    if (module.profile !== profile && module.profile != null) return null;
                    return (
                        <div className="module" key={module.title} onClick={() => history.push(`/module/${module.id}`)}>
                            <img src={module.image} alt={module.title} />
                            <p className="title">{module.title}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}