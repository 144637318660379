import { ProfileType } from "../../models/profile.model";
import Module from "../module";
import ShoppingList from "./ShoppingList";
import './shopping-list.scss';

export default {
    id: 'shopping-list',
    image: require('./../../assets/shopping-list-icon.png'),
    profile: ProfileType.personal,
    title: 'Lista de compras',
    render: ShoppingList
} as Module;