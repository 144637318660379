import firebase from 'firebase/app';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import _ from 'underscore';
import usePersistentState from '../../hooks/usePersistentState';
import Button from '../../uiKit/Button/Button';
import Tooltip from '../../uiKit/tooltip/Tooltip';
import './market.scss';
import { Item, Market } from './models';
import './person-cart.png';
import './person-product.png';

export default () => {
    const { marketId } = useParams<{marketId: string}>();
    const [purchases, setPurchases] = React.useState<Market[]|null>(null);
    const [topFive, setTopFive] = React.useState<Item[]|null>(null);
    const [marketDetail, setMarketDetail] = React.useState<Market|null>(null);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();

    React.useEffect(() => {
        (async() => {
            if (marketId) {
                setLoading(true);
                const marketDetail = await firebase
                    .firestore()
                    .collection('market')
                    .doc(marketId)
                    .get();

                setMarketDetail(marketDetail.data() as Market);
                setLoading(false);
            }

            const purchases = await firebase
                .firestore()
                .collection('market')
                .get();

            setPurchases(_.sortBy(purchases.docs.map((item) => item.data(), 'date').reverse()) as Market[]);

            const topFive = await firebase
                .firestore()
                .collection('market-items')
                .orderBy('quantity', 'desc')
                .limit(5)
                .get();

            setTopFive(topFive.docs.map((item) => item.data()) as Item[]);
        })();
    }, []);

    const divide = async (id: string, divisor: number) => {
        setLoading(true);

        try {
            const response = await fetch('https://extras.rafaeldantas.me/ocean/1/market/divide', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id,
                    divisor
                })
            });

            if (response.status !== 200) {
                swal(
                    'Ocorreu um erro!',
                    'Não foi possível dividir a conta.',
                    'error'
                );
                setLoading(false);
                return;
            }
    
            swal(
                'Sucesso!',
                'Enviado por whatsapp o template da divisão!',
                'success'
            );
            setLoading(false);
        } catch(err) {
            swal(
                'Ocorreu um erro!',
                'Não foi possível dividir a conta.',
                'error'
            );
            setLoading(false);
        }
    };

    const showDivideDialog = async() => {
        if (!marketDetail) return;
        swal({
            text: 'Por quantas pessoas deve ser feito a divisão?',
            content: 'input' as any,
            buttons: {
                cancel: true,
                confirm: true,
            }
        }).then((divisor) => {
            if (Number.isInteger(divisor) && divisor >= 1) {
                swal(
                    'Preencha corretamente!',
                    'Informe um valor númerico maior que 1 para dividir',
                    'error'
                )
                return;
            }

            divide(marketDetail.id, parseInt(divisor));
        })
    };

    if (loading) {
        return (
            <Tooltip>Carregando...</Tooltip>
        )
    }


    if (marketDetail) {
        return (
            <div className="details">
                <div className="info-content">
                    <div className="info">
                        <p>{marketDetail.date}</p>
                        <h1>{marketDetail.market}</h1>
                        <h2>{marketDetail.totalPaid.toLocaleString('pt-br', {currency: 'BRL', style: 'currency'})}</h2>


                        <Button color="blue" onClick={() => showDivideDialog()}>Dividir conta</Button> <Button color="black" onClick={() => setMarketDetail(null)}>Voltar</Button>
                    </div>
                </div>

                <div className="market-items">
                    {marketDetail.items.map((item) => (
                        <div className="item" key={item.code}>
                            <div className="title">
                                <h2>{item.title}</h2>
                                <p>{item.quantity} unidades</p>
                            </div>

                            <div className="price">
                                {item.valueUnit.toLocaleString('pt-br', {currency: 'BRL', style: 'currency'})}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <>
        <div style={{textAlign: 'center', marginTop: 15}}>
            <Button color="black" onClick={() => history.replace('/dashboard')}>Voltar para Dashboard</Button>
        </div>
        <div className="dashboard">
            <div className="card last-purchase" onClick={() => { if(purchases) setMarketDetail(purchases[0])}}>

                <p>Ultima compra</p>
                {purchases ? (
                    <>
                        <h3>{purchases[0].market}</h3>
                        <h2>{purchases[0].totalPaid.toLocaleString('pt-br', {currency: 'BRL', style: 'currency'})}</h2>
                        <p>{purchases[0].date}</p>
                    </>
                ) : (
                    <>
                        <div className="loading-text"></div>
                        <div className="loading-text medium"></div>
                        <div className="loading-text small"></div>
                    </>
                )}
            </div>

            <div className="card more-purchase">
                <p>Produto mais comprado</p>
                {topFive ? (
                    <>
                        <h2>{topFive[0].title}</h2>
                        <h3>{topFive[0].quantity} unidades</h3>
                    </>
                ) : (
                    <>
                        <div className="loading-text medium"></div>
                        <div className="loading-text"></div>
                    </>
                )}
                
            </div>

            <div className="card topfive">
                <p>TOP 5</p>
                {topFive ? (
                    <>
                        {topFive.map((item) => (
                            <p key={item.code}>{item.title} - {item.quantity} UN.</p>
                        ))}
                    </>
                ) : (
                    <>
                        <div className="loading-text medium"></div>
                        <div className="loading-text medium"></div>
                        <div className="loading-text medium"></div>
                        <div className="loading-text medium"></div>
                        <div className="loading-text medium"></div>
                    </>
                )}
                
            </div>
        </div>

        <div className="purchases">
            {purchases ? (
                <>
                    {purchases.map((purchase) => (
                        <div className="purchase" onClick={() => setMarketDetail(purchase)}>
                            <div className="title">
                                <h3>{purchase.market}</h3>
                                <p>{purchase.date}</p>
                            </div>
            
                            <div className="price">
                                {purchase.totalPaid.toLocaleString('pt-br', {currency: 'BRL', style: 'currency'})}
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {Array.from({length: 3}, (x, i) => (
                        <div className="purchase">
                            <div className="title">
                                <div className="loading-text inverted" style={{width: 250}}></div>
                                <div className="loading-text inverted small"></div>
                            </div>
            
                            <div className="price">
                                <div className="loading-text inverted" style={{width: 80}}></div>
                            </div>
                        </div>
                    ))} 
                </>
            )}

        </div>
        </>
    );
};