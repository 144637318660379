import * as React from 'react';
import './listMenu.scss';

type Item = {
    id: string,
    title: string
}

type Props = {
    withCreate?: any,
    items: Item[],
    onSelect: (id: Item) => void,
    selected: string|null
}

export default (props: Props) => {
    return (
        <div className="lists item-selected">
            {props.withCreate != null ? (
                <div className="list create" onClick={() => props.withCreate()}>
                    + Novo
                </div>
            ) : null}

            {props.items.map((list: any) => (
                <div key={list.id} className={`list ${props.selected == list.id ? 'selected' : ''}`} onClick={() => props.onSelect(list)}>
                    {list.title}
                </div>
            ))}
            
        </div>
    )
};