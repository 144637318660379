import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { MODULE_ROUTE_PREFIX } from '../module';
import Budgets from './budgets/Budgets';
import Earnings from './earnings/Earnings';
import Expenses from './expenses/Expenses';
import './finance.scss';
import FinanceDashboard from './FinanceDashboard';

export default () => {
    return (
        <Switch>
            <Route path={`${MODULE_ROUTE_PREFIX}/earnings`}>
                <Earnings />
            </Route>
            <Route path={`${MODULE_ROUTE_PREFIX}/expenses`}>
                <Expenses />
            </Route>
            <Route path={`${MODULE_ROUTE_PREFIX}/budgets`}>
                <Budgets />
            </Route>
            <Route path="/">
                <FinanceDashboard />
            </Route>
        </Switch>
    )
};