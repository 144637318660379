import { ProfileType } from "../../models/profile.model";
import Module from "../module";
import Dashwall from "./Dashwall";

export default {
    id: 'dashwall',
    image: require('./../../assets/dashwall-icon.png'),
    title: "Dashwall",
    profile: ProfileType.personal,
    render: Dashwall
} as Module;