import { ProfileType } from "../../models/profile.model";
import Module from "../module";
import Market from "./Market";
import Route from "./Route";

export default {
    id: 'market',
    image: require('./../../assets/market-icon.png'),
    profile: ProfileType.personal,
    title: 'Mercado',
    render: Route
} as Module;