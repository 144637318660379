import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { MODULE_ROUTE_PREFIX } from '../module';
import Market from './Market';

export default () => {
    return (
        <Switch>
            <Route path={`${MODULE_ROUTE_PREFIX}/:marketId`}>
               <Market /> 
            </Route>

            <Route path="/">
                <Market />
            </Route>
        </Switch>
    )
};