import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import * as React from 'react';
import usePersistentState from '../../hooks/usePersistentState';
import Button from '../../uiKit/Button/Button';
import Tooltip from '../../uiKit/tooltip/Tooltip';
import settings from '../settings';
import './lock-pin.scss';

export default ({onUnlock}: {onUnlock: () => void}) => {
    const [unlockAttempts, setUnlockAttempts] = usePersistentState('unlock_attempts', 0);
    const [expireIn, setExpireIn] = usePersistentState('block_expire_in', 0);
    const [unlockedUntil, setUnlockedUntil] = usePersistentState('unlocked_until', 0);
    const [now, setNow] = React.useState(new Date().getTime());
    const [code, setCode] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if ((new Date()).getTime() > unlockedUntil) {
            setLoading(false);
            return;
        }
        
        onUnlock();
        setLoading(false);
    }, [unlockedUntil]);

    const addToCode = (number: number) => {
        if (code.length >= 4) return;
        setCode(`${code}${number}`);
    };

    const confirmCode = () => {
        if (code == settings.lockPin.defaultPin) {
            setUnlockAttempts(0);
            setUnlockedUntil((new Date()).getTime() + settings.lockPin.session)
            return;
        }

        if (unlockAttempts >= 3) {
            setExpireIn((new Date()).getTime()+300000);
            return;
        }

        alert('Codígo informado é inválido.');
        setCode('');
        setUnlockAttempts(unlockAttempts+1);
    };

    if (now < expireIn) {
        {setTimeout(() => setNow(new Date().getTime()), 1000)}

        return (
            <div className="lock-pin">
                <p>Bloqueado por excesso de tentativas. <br /> Poderá tentar novamente em {(moment() as any).lang('pt-br').countdown(expireIn).toString()}</p>
            </div>
        )
    }

    if (loading) {
        return (<></>);
    }

    return (
        <div className="lock-pin">
            <h2>Digite o PIN para desbloquear</h2>

            <input disabled type="text" value={code.length >= 1 ? '*' : ''} />
            <input disabled type="text" value={code.length >= 2 ? '*' : ''} />
            <input disabled type="text" value={code.length >= 3 ? '*' : ''} />
            <input disabled type="text" value={code.length >= 4 ? '*' : ''} />

            <div className="keyboard">
                <div>
                    <Button className={code.length >= 4 ? 'disabled' : ''} onClick={() => addToCode(1)}>1</Button>
                    <Button className={code.length >= 4 ? 'disabled' : ''} onClick={() => addToCode(2)}>2</Button>
                    <Button className={code.length >= 4 ? 'disabled' : ''} onClick={() => addToCode(3)}>3</Button>
                </div>

                <div>
                    <Button className={code.length >= 4 ? 'disabled' : ''} onClick={() => addToCode(4)}>4</Button>
                    <Button className={code.length >= 4 ? 'disabled' : ''} onClick={() => addToCode(5)}>5</Button>
                    <Button className={code.length >= 4 ? 'disabled' : ''} onClick={() => addToCode(6)}>6</Button>
                </div>

                <div>
                    <Button className={code.length >= 4 ? 'disabled' : ''} onClick={() => addToCode(7)}>7</Button>
                    <Button className={code.length >= 4 ? 'disabled' : ''} onClick={() => addToCode(8)}>8</Button>
                    <Button className={code.length >= 4 ? 'disabled' : ''} onClick={() => addToCode(9)}>9</Button>
                </div>

                <div>
                    <Button color="red" onClick={() => setCode('')} className={code.length < 4 ? 'disabled' : ''}>
                        <FontAwesomeIcon icon={faTrash} className="icon" />
                    </Button>
                    <Button onClick={() => addToCode(0)} className={code.length >= 4 ? 'disabled' : ''}>0</Button>
                    <Button color="green" className={code.length < 4 ? 'disabled' : ''} onClick={confirmCode}>
                        <FontAwesomeIcon icon={faCheck} className="icon" />
                    </Button>
                </div>
            </div>
        </div>
    )
};